import {UIEvent, createRef, useCallback, useEffect, useState} from 'react';

interface Options {
  offsetY?: number;
}

export const scrollRef = createRef<any>();

export const useScroll = ({offsetY = 500}: Options) => {
  const [showBackTop, setShowBackTop] = useState<boolean>(false);

  const scrollToTop = useCallback(() => {
    scrollRef.current?.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  const handleScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const target = event.target as HTMLDivElement;
      const isShowBackTop = target.scrollTop >= offsetY;
      setShowBackTop(isShowBackTop);
    },
    [offsetY],
  );

  useEffect(() => {
    scrollRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      scrollRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return {showBackTop, scrollToTop};
};
