import { cloneDeep } from './common';

export const markOrderTable = <T extends any[]>(
  arr: T,
  level: string,
  perPage: number = 10,
  surplus: number = 1,
): T => {
  const firstLevel = !level.includes('.') && +level % perPage === surplus;
  const clonedArr = firstLevel ? cloneDeep<T>(arr) : arr;

  clonedArr.forEach((item, index) => {
    const currDepth = firstLevel ? `${+level + index}.` : `${level}${index + 1}.`;
    item.name = `${currDepth} ${item.name}`;
    item.firstLevel = firstLevel;
    markOrderTable(item.children, currDepth, perPage);
  });

  return clonedArr;
};

export const markOrderSelect = <T extends any[]>(arr: T, level?: number) => {
  const firstLevel = !level;
  const clonedArr = firstLevel ? cloneDeep<T>(arr) : arr;

  clonedArr.forEach(item => {
    const currDepth = firstLevel ? 1 : level + 1;
    item.name = `${currDepth}. ${item.name}`;
    item.firstLevel = firstLevel;
    markOrderSelect(item.children, currDepth);
  });

  return clonedArr;
};
