import {TemplateAction, TemplateStatus} from '~models';

export const templateStatusOptions = [
  {value: TemplateStatus.DRAFT, label: 'Bản nháp'},
  {value: TemplateStatus.INTERNAL_DEPARTMENT, label: 'Nội bộ'},
  {value: TemplateStatus.ALL, label: 'Công khai'},
];

export const templateStatusLabel: {[key: number]: string} = {
  [TemplateStatus.DRAFT]: 'Bản nháp',
  [TemplateStatus.INTERNAL_DEPARTMENT]: 'Nội bộ',
  [TemplateStatus.ALL]: 'Công khai',
};

export const templateActionOptions = [
  {value: TemplateAction.CHANGE_STATUS, label: 'Đổi trạng thái'},
];
