import {Dispatch, SetStateAction, createContext, useContext} from 'react';
import {Template} from '~models';

interface TemplateState {
  getTemplates: () => void;
  selectedTemplates: Template[];
  setSelectedTemplates: Dispatch<SetStateAction<Template[]>>;
}

export const TemplateContext = createContext<TemplateState | null>(null);

export const useTemplateContext = () => {
  const context = useContext(TemplateContext);

  if (!context) {
    throw new Error('useTemplateContext must be used within TemplateContext.Provider');
  }

  return context;
};
