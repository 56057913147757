import {Avatar, Flex, Tooltip} from 'antd';
import {ColumnsType} from 'antd/es/table';
import Highlighter from 'react-highlight-words';

import {DeleteIcon} from '~assets';
import {CommonObject, Employee, Pagination} from '~models';
import {deleteEmployeesThunk} from '~reducers';
import {AppDispatch} from '~store';
import {getSlug, showConfirmModal} from '~utils';

interface GetColumns {
  searchTerm?: string;
  groupPermissionId: string;
  employeesLength: number;
  pagination: Pagination;
  dispatch: AppDispatch;
  setSearchParams: (values: CommonObject<string>) => void;
  getEmployees: () => void;
}

export const getEmployeeColumns = ({
  searchTerm,
  groupPermissionId,
  employeesLength,
  pagination,
  dispatch,
  setSearchParams,
  getEmployees,
}: GetColumns): ColumnsType<Employee> => {
  const deleteEmployee = (employee: Employee) => {
    showConfirmModal({
      title: 'Xoá nhân viên',
      content: 'Sau khi xác nhận xóa bạn sẽ không thể hoàn tác',
      onOk: () => {
        dispatch(
          deleteEmployeesThunk({
            payload: {
              groupPermissionId,
              employees: [employee],
            },
            callback: () => {
              if (pagination.current_page > 1 && employeesLength === 1) {
                setSearchParams({current_page: 1});
              } else getEmployees();
            },
          }),
        );
      },
    });
  };

  const renderEmployee = (_: unknown, employee: Employee) => (
    <Flex align="center" gap={10}>
      <Avatar src={<img src={employee.avatar} alt="Avatar" />} />
      <Highlighter
        searchWords={[String(searchTerm)]}
        textToHighlight={employee.name}
        sanitize={getSlug}
      />
    </Flex>
  );

  const renderUsername = (username: string) => (
    <Highlighter searchWords={[String(searchTerm)]} textToHighlight={username} sanitize={getSlug} />
  );

  const renderActions = (_: unknown, employee: Employee) => (
    <div className="icons">
      <Tooltip title="Xoá nhân viên" placement="bottomRight">
        <DeleteIcon className="icons__delete" onClick={() => deleteEmployee(employee)} />
      </Tooltip>
    </div>
  );

  return [
    {
      title: 'Nhân viên',
      dataIndex: 'name',
      key: 'name',
      render: renderEmployee,
    },
    {
      title: 'Tên tài khoản',
      dataIndex: 'username',
      key: 'username',
      width: '12%',
      render: renderUsername,
    },
    {
      title: 'Phòng ban',
      dataIndex: 'company',
      key: 'company',
      width: '30%',
    },
    {
      title: 'Thao tác',
      width: '12%',
      render: renderActions,
    },
  ];
};
