import {TaskGroup} from '~models';

import {axiosClient} from './axios-client';
import {ListResponse, ObjectResponse, SuccessResponse} from './service-models';

interface GetTaskGroupsAPIParams {
  search?: string;
  page?: number;
  per_page?: number;
}

interface EditTaskGroupAPIPayload {
  id: string;
  name: string;
}

export const addTaskGroupAPI = (name: string) => {
  return axiosClient.post<SuccessResponse>('/task_groups', {name});
};

export const editTaskGroupAPI = ({id, name}: EditTaskGroupAPIPayload) => {
  return axiosClient.patch<SuccessResponse>(`/task_groups/${id}`, {name});
};

export const getTaskGroupsAPI = (params: GetTaskGroupsAPIParams) => {
  return axiosClient.get<ListResponse<TaskGroup>>('/task_groups', {params});
};

export const getTaskGroupByIdAPI = (id: string) => {
  return axiosClient.get<ObjectResponse<TaskGroup>>(`/task_groups/${id}`);
};

export const deleteTaskGroupAPI = (id: string) => {
  return axiosClient.delete<SuccessResponse>(`task_groups/${id}`);
};
