import { SVGAttributes } from 'react';

const SaveIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.684 12.596c.333.177.66.27.97.27.263 0 .504-.064.716-.185.475-.276.744-.829.744-1.508V3.125A2.736 2.736 0 0013.38.392H7.869a2.736 2.736 0 00-2.734 2.734H3.612A2.736 2.736 0 00.878 5.86v8.047c0 .55.173 1.015.486 1.32.08.078.168.146.265.203.467.276 1.083.247 1.685-.085l2.777-1.552a.763.763 0 01.56 0l2.783 1.552c.333.177.66.269.97.269.263 0 .504-.064.716-.184.475-.277.744-.83.744-1.51v-2.34l1.82 1.016zm-1.82-2.238l2.337 1.31c.255.142.49.177.63.092.143-.085.22-.304.22-.595V3.12c0-.921-.75-1.672-1.671-1.672H7.869c-.92 0-1.672.75-1.672 1.672v.007h2.926c.75 0 1.43.303 1.923.792.505.496.818 1.186.818 1.95v4.49zM5.582 4.196H3.619c-.92 0-1.671.75-1.671 1.671v8.047c0 .176.03.328.085.438.036.072.08.126.134.157.135.078.375.042.63-.1l2.784-1.55.03-.016c.453-.232 1.113-.227 1.557.016l2.784 1.55c.251.14.482.177.624.103l.006-.003c.134-.078.22-.305.22-.595v-8.04-.002a1.675 1.675 0 00-1.672-1.67H5.666a.522.522 0 01-.084-.006z"
      fill="#fff"
    />
  </svg>
);

export default SaveIcon;
