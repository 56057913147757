import {GroupPermission, Permission} from '~models';

import {axiosClient} from './axios-client';
import {ListResponse, ObjectResponse, SuccessResponse} from './service-models';

interface GetGroupPermissionsAPIParams {
  page?: number;
  per_page?: number;
}

interface EditGroupPermissionPayload {
  id: string;
  name: string;
  permissions: Permission[];
}

export const addGroupPermissionAPI = (name: string) => {
  return axiosClient.post<SuccessResponse>('/group-permissions', {name});
};

export const getGroupPermissionsAPI = (params: GetGroupPermissionsAPIParams) => {
  return axiosClient.get<ListResponse<GroupPermission>>('/group-permissions', {params});
};

export const deleteGroupPermissionAPI = (id: string) => {
  return axiosClient.delete<SuccessResponse>(`/group-permissions/${id}`);
};

export const editGroupPermissionAPI = ({id, ...body}: EditGroupPermissionPayload) => {
  return axiosClient.patch<SuccessResponse>(`/group-permissions/${id}`, body);
};

export const getGroupPermissionById = (id: string) => {
  return axiosClient.get<ObjectResponse<GroupPermission>>(`/group-permissions/${id}`);
};
