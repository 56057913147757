import {ChangeEvent} from 'react';

import {statusOptions} from '~variables';
import {Input, Select} from '~components';
import {useDebounceFunction} from '~hooks';
import {TaskFilterParams} from '~models';

import './styles.scss';

interface Props {
  searchParams: TaskFilterParams;
  setSearchParams: (values: TaskFilterParams) => void;
}

const TaskFilter = ({searchParams, setSearchParams}: Props) => {
  const handleSearchChange = useDebounceFunction<ChangeEvent<HTMLInputElement>>(event => {
    setSearchParams({search: event.target.value});
  });

  return (
    <div className="task-filter">
      <Input
        className="field"
        placeholder="Nhập nội dung"
        label="Tên nội dung kiểm soát"
        horizontal
        allowClear
        defaultValue={searchParams.search}
        onChange={handleSearchChange}
      />
      <Select
        className="field"
        placeholder="---Chọn trạng thái---"
        label="Trạng thái"
        horizontal
        allowClear
        options={statusOptions}
        value={searchParams.root_active}
        onChange={root_active => setSearchParams({root_active})}
      />
    </div>
  );
};

export default TaskFilter;
