import { SVGAttributes } from 'react';

const SearchIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.833 7.666c0 3.766 3.067 6.833 6.833 6.833 3.767 0 6.834-3.067 6.834-6.833 0-3.767-3.067-6.833-6.834-6.833C3.9.833.833 3.899.833 7.666zm1 0a5.84 5.84 0 015.833-5.833A5.84 5.84 0 0113.5 7.666a5.84 5.84 0 01-5.834 5.833 5.84 5.84 0 01-5.833-5.833zm12.48 7.354c.1.1.227.146.353.146a.494.494 0 00.354-.146.503.503 0 000-.707l-1.334-1.334a.503.503 0 00-.706 0 .503.503 0 000 .707l1.333 1.334z"
      fill="#CCC"
    />
  </svg>
);

export default SearchIcon;
