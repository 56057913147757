import { SVGAttributes } from 'react';

const LogoutIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.973 19.415h-.12c-4.07 0-6.031-1.604-6.37-5.197a.689.689 0 01.623-.752.7.7 0 01.752.623c.266 2.879 1.622 3.951 5.005 3.951h.119c3.73 0 5.05-1.32 5.05-5.05V7.012c0-3.731-1.32-5.051-5.05-5.051h-.12c-3.4 0-4.757 1.09-5.004 4.024a.695.695 0 01-.752.623.689.689 0 01-.632-.742c.311-3.648 2.282-5.28 6.38-5.28h.119c4.5 0 6.426 1.925 6.426 6.426v5.976c0 4.501-1.925 6.426-6.426 6.426zm-10.02-8.727h9.8a.692.692 0 00.687-.687.692.692 0 00-.687-.688H2.954l1.897-1.897a.692.692 0 000-.971.692.692 0 00-.972 0L.81 9.515a.692.692 0 000 .972l3.07 3.071a.68.68 0 00.486.202.665.665 0 00.486-.202.692.692 0 000-.972l-1.898-1.898z"
      fill="#fff"
    />
  </svg>
);

export default LogoutIcon;
