import { SVGAttributes } from 'react';

const NextIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={6}
    height={10}
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M5.136 3.994L3.822 2.681 1.682.54a.721.721 0 00-1.226.513v7.894c0 .64.773.96 1.226.506l3.454-3.453a1.42 1.42 0 000-2.007z"
      fill="#737373"
    />
  </svg>
);

export default NextIcon;
