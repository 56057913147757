import {Tag, Tooltip} from 'antd';
import {ColumnsType} from 'antd/es/table';

import {DeleteIcon, EditIcon} from '~assets';
import {CommonObject, GroupPermission, Pagination} from '~models';
import {deleteGroupPermissionThunk} from '~reducers';
import {navigate} from '~routes';
import {AppDispatch} from '~store';
import {showConfirmModal} from '~utils';
import {AllPermission} from '~variables';

interface GetColumns {
  allowedPermissions: string[];
  groupPermissionsLength: number;
  pagination: Pagination;
  dispatch: AppDispatch;
  setSearchParams: (values: CommonObject<string>) => void;
  getGroupPermissions: () => void;
}

export const getGroupPermissionColumns = ({
  allowedPermissions,
  groupPermissionsLength,
  pagination,
  dispatch,
  setSearchParams,
  getGroupPermissions,
}: GetColumns): ColumnsType<GroupPermission> => {
  const deleteGroupPermission = (groupPermission: GroupPermission) => {
    showConfirmModal({
      title: 'Xoá vai trò',
      content: 'Sau khi xác nhận xóa bạn sẽ không thể hoàn tác',
      onOk: () => {
        dispatch(
          deleteGroupPermissionThunk({
            id: groupPermission.id,
            callback: () => {
              if (pagination.current_page > 1 && groupPermissionsLength === 1) {
                setSearchParams({current_page: 1});
              } else getGroupPermissions();
            },
          }),
        );
      },
    });
  };

  const editGroupPermission = (groupPermission: GroupPermission) => {
    navigate(`/group-permission/${groupPermission.id}/permission`, {
      state: {
        groupPermissionId: groupPermission.id,
      },
    });
  };

  const viewEmployee = (groupPermission: GroupPermission) => {
    navigate(`/group-permission/${groupPermission.id}/employee`, {
      state: {
        groupPermissionId: groupPermission.id,
      },
    });
  };

  const renderActions = (_: unknown, record: GroupPermission) => (
    <div className="icons">
      {allowedPermissions.includes(AllPermission.Group_permissions__Update) && (
        <Tooltip title="Chỉnh sửa" placement="bottomRight">
          <EditIcon className="icons__edit" onClick={() => editGroupPermission(record)} />
        </Tooltip>
      )}
      {allowedPermissions.includes(AllPermission.Group_permissions__Delete) && (
        <Tooltip title="Xoá vai trò" placement="bottomRight">
          <DeleteIcon className="icons__delete" onClick={() => deleteGroupPermission(record)} />
        </Tooltip>
      )}
    </div>
  );

  const renderEmployees = (_: unknown, record: GroupPermission) => (
    <Tag
      color="processing"
      onClick={() => viewEmployee(record)}
      className="group-permission__table-employee">
      Danh sách
    </Tag>
  );

  return [
    {
      title: 'Tên vai trò',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Danh sách nhân viên',
      dataIndex: 'employees',
      key: 'employees',
      width: '20%',
      render: renderEmployees,
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      width: '12%',
      render: renderActions,
    },
  ];
};
