import {SVGAttributes} from 'react';

const CircleUserIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.771 15.126c3.593-.384 6.398-3.432 6.398-7.125A7.173 7.173 0 008.003.834 7.173 7.173 0 00.836 8.001c0 3.703 2.819 6.756 6.425 7.128a7.174 7.174 0 001.51-.003zm3.943-3.151A6.14 6.14 0 0014.17 8c0-3.4-2.766-6.167-6.166-6.167a6.174 6.174 0 00-6.167 6.167c0 1.512.547 2.9 1.454 3.973.24-.446.618-.853 1.12-1.187 1.986-1.32 5.206-1.32 7.186 0 .5.338.88.743 1.118 1.188zM7.43 14.14a6.22 6.22 0 001.163-.002 6.137 6.137 0 003.356-1.405c-.12-.406-.44-.8-.913-1.12-1.64-1.093-4.42-1.093-6.073 0-.474.32-.787.713-.907 1.12a6.136 6.136 0 003.374 1.407zm.653-5.12h-.114c-1.513-.047-2.646-1.227-2.646-2.68a2.692 2.692 0 012.686-2.687 2.692 2.692 0 012.687 2.687 2.676 2.676 0 01-2.593 2.68h-.02zm-.08-4.373c-.934 0-1.687.76-1.687 1.686a1.68 1.68 0 001.62 1.687.56.56 0 01.153 0 1.689 1.689 0 00-.087-3.373z"
      fill="#333"
    />
  </svg>
);

export default CircleUserIcon;
