import {Form} from 'antd';
import {DefaultOptionType} from 'antd/es/select';

import {Select} from '~components';
import {useGetDataSelect} from '~hooks';
import {Employee} from '~models';
import {getAllUserAPI} from '~services';

import {AddEmployeeForm} from '../utils';

const SelectMultiEmployee = () => {
  const {
    data: employees,
    dataOptions: employeeOptions,
    handleSearchChange,
    handleEndReached,
  } = useGetDataSelect<Employee>(getAllUserAPI);

  const formInstance = Form.useFormInstance<AddEmployeeForm>();

  const {getFieldsValue, setFieldValue, resetFields} = formInstance;

  const handleSelect = ({value}: DefaultOptionType) => {
    const {employees: selectedEmployees} = getFieldsValue();
    const selected = employees.find(em => em.id === value);
    if (selected) {
      setFieldValue('employees', selectedEmployees.concat(selected));
    }
  };

  const handleDeselect = ({value}: DefaultOptionType) => {
    const {employees: selectedEmployees} = getFieldsValue();
    setFieldValue(
      'employees',
      selectedEmployees.filter(em => em.id !== value),
    );
  };

  return (
    <Select
      label="Danh sách nhân viên"
      placeholder="Nhập tên nhân viên"
      mode="multiple"
      required
      allowClear
      labelInValue
      filterOption={false}
      options={employeeOptions}
      onSearch={handleSearchChange}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      onEndReached={handleEndReached}
      onClear={() => resetFields(['employees'])}
    />
  );
};

export default SelectMultiEmployee;
