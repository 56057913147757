import {ChangeEvent} from 'react';

import {Input, Select} from '~components';
import {useDebounceFunction} from '~hooks';
import {TemplateFilterParams} from '~models';
import {templateStatusOptions} from '~variables';

import SelectDepartment from './SelectDepartment';
import FilterAction from './FilterAction';
import './styles.scss';

interface Props {
  isPublicPage: boolean;
  searchParams: TemplateFilterParams;
  setSearchParams: (values: TemplateFilterParams) => void;
}

const TemplateFilter = ({isPublicPage, searchParams, setSearchParams}: Props) => {
  const handleSearchChange = useDebounceFunction<ChangeEvent<HTMLInputElement>>(event => {
    setSearchParams({search: event.target.value});
  });

  return (
    <div className="template-filter">
      {!isPublicPage && <FilterAction />}
      <Input
        key={Date.now()}
        className="field"
        label="Tên checklist"
        placeholder="Nhập tên"
        horizontal
        allowClear
        defaultValue={searchParams.search}
        onChange={handleSearchChange}
      />
      {isPublicPage && (
        <SelectDepartment
          selectedDepartmentId={searchParams.department}
          setSearchParams={setSearchParams}
        />
      )}
      {!isPublicPage && (
        <Select
          className="field"
          placeholder="---Chọn trạng thái---"
          label="Trạng thái"
          horizontal
          allowClear
          options={templateStatusOptions}
          value={searchParams.status}
          onChange={status => setSearchParams({status})}
        />
      )}
    </div>
  );
};

export default TemplateFilter;
