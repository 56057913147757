import {Rule} from 'antd/es/form';

import {Employee} from '~models';

export interface AddEmployeeForm {
  employees: Employee[];
}

export const initialEmployeeValues: AddEmployeeForm = {
  employees: [],
};

export const employeeRules: Rule[] = [
  {
    required: true,
    message: 'Không được để trống',
  },
];
