import {memo, useMemo} from 'react';
import {Form} from 'antd';

import {Input, Text, TreeTable} from '~components';
import {GetDataSelectMode, useGetDataSelect} from '~hooks';
import {Task} from '~models';
import {getTasksAPI} from '~services';
import {SearchIcon} from '~assets';
import {getSelectedTrees, getSelectedLeafIds, getSelectedTreesSelectChange} from '~utils';

import {AddTemplateForm, getTaskColumns} from '../../utils';
import './styles.scss';

const SelectTasks = () => {
  const {
    isLoading,
    data: tasks,
    pagination,
    searchTerm,
    setPagination,
    handleSearchChange,
  } = useGetDataSelect<Task>(getTasksAPI, GetDataSelectMode.PAGE);

  const formInstance = Form.useFormInstance<AddTemplateForm>();
  const selectedTasks = Form.useWatch<Task[]>('tasks') ?? [];

  const {setFieldValue} = formInstance;
  const selectedTaskIds = getSelectedLeafIds(selectedTasks);

  const handleSelectRow = (record: Task, selected: boolean) => {
    const rootTasks = getSelectedTrees(tasks, [record.id]);
    const newSelectedTasks = getSelectedTreesSelectChange(selectedTasks, rootTasks, selected);
    setFieldValue('tasks', newSelectedTasks);
  };

  const handleSelectAll = (selected: boolean, selectedRows: Task[], changeRows: Task[]) => {
    const rootTasks = changeRows.filter(row => row.firstLevel);
    const newSelectedTasks = getSelectedTreesSelectChange(selectedTasks, rootTasks, selected);
    setFieldValue('tasks', newSelectedTasks);
  };

  const columns = useMemo(() => getTaskColumns({searchTerm}), [searchTerm]);

  return (
    <div className="tasks">
      <div className="tasks__header">
        <Text className="tasks__header-label">Danh sách nội dung trong checklist</Text>
        <Input
          className="tasks__header-input"
          placeholder="Nhập nội dung kiểm soát cần tìm kiếm"
          allowClear
          prefix={<SearchIcon />}
          onChange={event => handleSearchChange(event.target.value)}
        />
      </div>
      <TreeTable
        className="tasks__table"
        emptyText="Danh sách trống"
        showScroll={false}
        loading={isLoading}
        columns={columns}
        dataSource={tasks}
        rowSelection={{
          checkStrictly: false,
          selectedRowKeys: selectedTaskIds,
          onSelect: handleSelectRow,
          onSelectAll: handleSelectAll,
        }}
        pagination={{
          from: pagination.from,
          to: pagination.to,
          total: pagination.total,
          current: pagination.current_page,
          pageSize: pagination.per_page,
          onChange: current_page => setPagination({...pagination, current_page}),
          onShowSizeChange: (_, per_page) => {
            setPagination({...pagination, per_page, current_page: 1});
          },
        }}
      />
    </div>
  );
};

const MemorizedSelectTasks = () => (
  <Form.Item name="tasks">
    <SelectTasks />
  </Form.Item>
);

export default memo(MemorizedSelectTasks);
