import { Flex } from 'antd';

import { useAppSelector } from '~store';
import { Input, Select, Text } from '~components';

import './styles.scss';

const ProfilePage = () => {
  const user = useAppSelector(state => state.authReducer.user);

  return (
    <div className="profile">
      <Text className="profile__title">THÔNG TIN CÁ NHÂN</Text>

      <div className="info">
        <div className="brief">
          <div className="header">
            <img
              className="header__avatar"
              src="https://img.freepik.com/free-photo/puppy-that-is-walking-snow_1340-37228.jpg"
            />
            <Flex className="header__contact" vertical>
              <Text className="header__contact-name">{user?.name}</Text>
              <Text className="header__contact-email">{user?.email}</Text>
            </Flex>
          </div>

          <Flex className="content" vertical gap={16}>
            <div className="content__item">
              <Text className="content__item-label">Mã nhân viên:</Text>
              <Text className="content__item-value">{user?.username}</Text>
            </div>
            <div className="content__item">
              <Text className="content__item-label">Vai trò:</Text>
              <Text className="content__item-value">{user?.position}</Text>
            </div>
            <div className="content__item">
              <Text className="content__item-label">Danh mục:</Text>
              <Text className="content__item-value">{user?.company}</Text>
            </div>
          </Flex>
        </div>

        <Flex className="details" vertical gap={16}>
          <Input label="Chức vụ" value={user?.position} disabled />
          <Flex gap={24}>
            <Input className="details__phone" label="Số điện thoại" value={user?.phone} disabled />
            <Input
              className="details__birthday"
              label="Ngày sinh"
              value={user?.bod?.toString()}
              disabled
            />
          </Flex>
          <Select label="Nơi làm việc" disabled />
          <Input label="Phòng ban" value={user?.company} disabled />
          <Flex gap={24}>
            <Input className="details__telegram" label="Telegram" value={user?.phone} disabled />
            <Input className="details__zalo" label="Zalo" value={user?.phone} disabled />
          </Flex>
        </Flex>
      </div>
    </div>
  );
};

export default ProfilePage;
