import { SVGAttributes } from 'react';

const PlusSquareOutlinedIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.833 9.999c0 3.62 1.547 5.166 5.167 5.166h4c3.62 0 5.166-1.546 5.166-5.166v-4c0-3.62-1.546-5.167-5.166-5.167H6C2.38.832.833 2.379.833 5.999v4zm1-4c0-3.074 1.093-4.167 4.167-4.167h4c3.073 0 4.166 1.093 4.166 4.167v4c0 3.073-1.093 4.166-4.166 4.166H6c-3.074 0-4.167-1.093-4.167-4.166v-4zM8 11.165a.504.504 0 01-.5-.5V8.5H5.333a.504.504 0 01-.5-.5c0-.274.227-.5.5-.5H7.5V5.332c0-.273.226-.5.5-.5.273 0 .5.227.5.5v2.167h2.166c.274 0 .5.226.5.5 0 .273-.226.5-.5.5H8.5v2.166c0 .274-.227.5-.5.5z"
      fill="#333"
    />
  </svg>
);

export default PlusSquareOutlinedIcon;
