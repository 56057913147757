import { SVGAttributes } from 'react';

const BackIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg width={16} height={12} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.533 11.5a.618.618 0 00.442.183.605.605 0 00.442-.183.629.629 0 000-.884L2.425 6.625h12.658A.63.63 0 0015.708 6a.63.63 0 00-.625-.625H2.425l3.992-3.992a.629.629 0 000-.883.629.629 0 00-.884 0L.475 5.558a.629.629 0 000 .883L5.533 11.5z"
    />
  </svg>
);

export default BackIcon;
