import {createSlice} from '@reduxjs/toolkit';

interface EmployeeState {}

const initialState: EmployeeState = {};

const employeeSlice = createSlice({
  name: 'EMPLOYEE_SLICE',
  initialState,
  reducers: {},
});

export const employeeReducer = employeeSlice.reducer;
export * from './async-actions';
