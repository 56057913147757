import {Modal, ModalFuncProps} from 'antd';

export const showInfoModal = (props: ModalFuncProps) => {
  Modal.info({
    icon: null,
    footer: null,
    closable: true,
    maskClosable: true,
    width: 800,
    style: {top: 30},
    ...props,
  });
};

export const showConfirmModal = (props: ModalFuncProps) => {
  Modal.confirm({
    cancelText: 'Huỷ',
    okText: 'Đồng ý',
    closable: true,
    maskClosable: true,
    okButtonProps: {loading: false, type: 'primary'},
    ...props,
  });
};
