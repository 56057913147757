import {PlusCircleOutlined} from '@ant-design/icons';
import {useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Button, PageHeader, Table} from '~components';
import {useGetData} from '~hooks';
import {Employee, EmployeeFilterParams, ModalMode} from '~models';
import {getEmployeesThunk} from '~reducers';
import {navigate} from '~routes';

import {AddEmployeeModal, EmployeeFilter} from '../../components';
import {getEmployeeColumns} from '../../utils';
import './styles.scss';

const EmployeePage = () => {
  const [modalMode, setModalMode] = useState<ModalMode>();

  const {groupPermissionId = ''} = useParams();

  const {
    isLoading,
    searchParams,
    pagination,
    data: employees,
    getData: getEmployees,
    setSearchParams,
    dispatch,
  } = useGetData<Employee, EmployeeFilterParams>(getEmployeesThunk, {
    groupPermissionId,
  });

  const columns = useMemo(() => {
    return getEmployeeColumns({
      searchTerm: searchParams.search,
      employeesLength: employees.length,
      groupPermissionId,
      pagination,
      dispatch,
      getEmployees,
      setSearchParams,
    });
  }, [
    searchParams.search,
    employees.length,
    groupPermissionId,
    pagination,
    dispatch,
    getEmployees,
    setSearchParams,
  ]);

  return (
    <div className="employee">
      <PageHeader
        title="DANH SÁCH NHÂN VIÊN"
        backUrl="/group-permission"
        actions={
          <Button
            type="primary"
            size="large"
            icon={<PlusCircleOutlined />}
            onClick={() => setModalMode(ModalMode.ADD)}>
            Thêm mới
          </Button>
        }
      />

      <EmployeeFilter searchParams={searchParams} setSearchParams={setSearchParams} />

      <Table
        className="employee__table"
        emptyText="Danh sách trống"
        columns={columns}
        dataSource={employees}
        loading={isLoading}
        pagination={{
          from: pagination.from,
          to: pagination.to,
          total: pagination.total,
          current: pagination.current_page,
          pageSize: pagination.per_page,
          onChange: current_page => setSearchParams({current_page}),
          onShowSizeChange: (_, per_page) => setSearchParams({per_page, current_page: 1}),
        }}
      />

      <AddEmployeeModal
        open={!!modalMode}
        groupPermissionId={groupPermissionId}
        setModalMode={setModalMode}
        getEmployees={getEmployees}
      />
    </div>
  );
};

export default EmployeePage;
