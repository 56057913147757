import {useMemo} from 'react';
import {Input} from 'antd';
import {TextAreaProps} from 'antd/es/input';

import Text from '../Text';

import './styles.scss';

const LINE_HEIGHT = 18;
const TOTAL_PADDING_VERTICAL = 12;

interface Props extends TextAreaProps {
  className?: string;
  label?: string;
  errorMsg?: string;
  required?: boolean;
  numberOfLines?: number;
}

const TextArea = ({className, label, errorMsg, required, numberOfLines = 2, ...props}: Props) => {
  const textAreaHeight = useMemo(() => {
    return LINE_HEIGHT * numberOfLines + TOTAL_PADDING_VERTICAL;
  }, [numberOfLines]);

  return (
    <div className={`textarea ${className}`}>
      {label && (
        <div className="textarea__label">
          <Text>{label}</Text>
          {required && <Text className="textarea__label-required">*</Text>}
        </div>
      )}
      <Input.TextArea style={{height: textAreaHeight}} status={errorMsg && 'error'} {...props} />
      {errorMsg && <Text className="textarea__error">{errorMsg}</Text>}
    </div>
  );
};

export default TextArea;
