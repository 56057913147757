import {Flex, Tag} from 'antd';
import {ColumnsType} from 'antd/es/table';
import Highlighter from 'react-highlight-words';

import {Task, TaskGroup} from '~models';
import {getSlug, splitOrderAndLines} from '~utils';

interface GetColumns {
  searchTerm: string;
}

export const getTaskColumns = ({searchTerm}: GetColumns): ColumnsType<Task> => {
  const renderName = (name: string) => {
    const {order, lines} = splitOrderAndLines(name);
    const mappedLines = lines.map(l => (
      <Highlighter
        key={l}
        searchWords={[String(searchTerm)]}
        textToHighlight={l}
        sanitize={getSlug}
      />
    ));
    return (
      <Flex className="task-name">
        <span>{order}</span>&nbsp;
        <Flex vertical>{mappedLines}</Flex>
      </Flex>
    );
  };

  const renderTaskGroups = (taskGroups: TaskGroup[]) => {
    return (
      <Flex wrap="wrap" gap={6}>
        {taskGroups?.map(tg => (
          <Tag key={tg.id}>{tg.name}</Tag>
        ))}
      </Flex>
    );
  };

  return [
    {
      title: 'Nội dung',
      dataIndex: 'name',
      key: 'name',
      width: 600,
      render: renderName,
    },
    {
      title: 'Nhóm lỗi tham chiếu',
      dataIndex: 'groups',
      key: 'groups',
      width: 400,
      render: renderTaskGroups,
    },
  ];
};
