import {Input as AntdInput, InputProps} from 'antd';

import {classNames} from '~utils';

import Text from '../Text';
import './styles.scss';

interface Props extends InputProps {
  className?: string;
  label?: string;
  horizontal?: boolean;
  errorMsg?: string;
  required?: boolean;
}

const Input = ({className, label, errorMsg, required, horizontal, ...props}: Props) => {
  return (
    <div
      className={classNames(`input ${className}`, {
        'input--horizontal': horizontal,
      })}>
      {label && (
        <div className="input__label">
          <Text className="input__label-text">{label}</Text>
          {required && <Text className="input__label-required">*</Text>}
        </div>
      )}
      <AntdInput className="input__main" status={errorMsg && 'error'} {...props} />
      {errorMsg && <Text className="input__error">{errorMsg}</Text>}
    </div>
  );
};

export default Input;
