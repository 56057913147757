import {useEffect, useMemo} from 'react';
import {Flex, Form} from 'antd';
import {useParams} from 'react-router-dom';

import {Button, Input, PageHeader, Table, Text} from '~components';
import {CircleUserIcon} from '~assets';
import {getGroupPermissionById} from '~services';
import {localModules} from '~variables';
import {useAppDispatch} from '~store';
import {Permission} from '~models';
import {editGroupPermissionThunk} from '~reducers';

import {
  PermissionForm,
  feedbackIcons,
  getPermissionColumns,
  groupPermissionNameRules,
  initialPermissionValues,
} from '../../utils';
import './styles.scss';
import {navigate} from '~routes';

const Permissions = () => {
  const dispatch = useAppDispatch();
  const {groupPermissionId = ''} = useParams();
  const [form] = Form.useForm<PermissionForm>();
  const permissions = Form.useWatch<Permission[]>('permissions', form);

  const {submit, setFieldsValue, setFieldValue} = form;

  const handleSubmit = (values: PermissionForm) => {
    dispatch(
      editGroupPermissionThunk({
        payload: {
          ...values,
          id: groupPermissionId,
        },
        callback: () => navigate('/group-permission'),
      }),
    );
  };

  useEffect(() => {
    getGroupPermissionById(groupPermissionId).then(response => {
      setFieldsValue(response.data.data);
    });
  }, [groupPermissionId, setFieldsValue]);

  const columns = useMemo(() => {
    return getPermissionColumns({
      permissions,
      setFieldValue,
    });
  }, [permissions, setFieldValue]);

  return (
    <div className="permissions">
      <Form
        initialValues={initialPermissionValues}
        form={form}
        onFinish={handleSubmit}
        feedbackIcons={feedbackIcons}>
        <PageHeader
          title="CHI TIẾT VAI TRÒ"
          backUrl="/group-permission"
          actions={
            <Button type="primary" size="large" onClick={submit}>
              Lưu thông tin
            </Button>
          }
        />

        <Flex className="permission-group" align="center">
          <CircleUserIcon />
          <Text className="permission-group__label">Vai trò:</Text>
          <Form.Item name="name" noStyle hasFeedback rules={groupPermissionNameRules}>
            <Input className="permission-group__value" />
          </Form.Item>
        </Flex>

        <Form.Item name="permissions">
          <Table
            className="permissions__table"
            emptyText="Danh sách trống"
            bordered
            showScroll={false}
            footer={undefined}
            pagination={{pageSize: 100}}
            columns={columns}
            dataSource={localModules}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default Permissions;
