import {createAsyncThunk} from '@reduxjs/toolkit';
import {HttpStatusCode} from 'axios';
import {notification} from 'antd';

import {
  addTemplateAPI,
  deleteTemplateAPI,
  editTemplateAPI,
  editTemplateStatusAPI,
  getTemplatesAPI,
} from '~services';
import {TemplateFilterParams, TemplateStatus} from '~models';
import {
  cloneDeep,
  getSearchParamsWindow,
  isNullOrUndefined,
  removeUnusedFields,
  sortTreesCreatedAtDESC,
} from '~utils';

import {
  AddTemplatePayload,
  DeleteTemplatePayload,
  EditTemplatePayload,
  EditTemplateStatusPayload,
  GetTemplatesPayload,
} from './action-models';

export const addTemplateThunk = createAsyncThunk(
  'TEMPLATE_SLICE/ADD_TEMPLATE_THUNK',
  async ({payload, callback}: AddTemplatePayload) => {
    try {
      let sortedTasks = cloneDeep(payload.tasks);
      sortTreesCreatedAtDESC(sortedTasks);
      sortedTasks = removeUnusedFields(sortedTasks);

      const response = await addTemplateAPI({
        name: payload.name,
        status: payload.status as TemplateStatus,
        type: payload.type?.id as string,
        tasks: sortedTasks,
        description: payload.description,
      });

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Thêm mới thành công',
          description: 'Thêm mới checklist kiểm soát thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Thêm mới thất bại',
        description: error.response.data.message,
      });
    }
  },
);

export const getTemplatesThunk = createAsyncThunk(
  'TEMPLATE_SLICE/GET_TEMPLATES_THUNK',
  async ({setIsLoading, callback}: GetTemplatesPayload) => {
    try {
      setIsLoading(true);
      const searchParams = getSearchParamsWindow<TemplateFilterParams>();

      const response = await getTemplatesAPI({
        category: searchParams.category,
        page: searchParams.current_page,
        per_page: searchParams.per_page,
        search: searchParams.search,
        statuses: isNullOrUndefined(searchParams.status) ? undefined : [searchParams.status],
        department: searchParams.department,
      });

      if (response.status === HttpStatusCode.Ok) {
        callback(response.data.data);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  },
);

export const editTemplateThunk = createAsyncThunk(
  'TEMPLATE_SLICE/EDIT_TEMPLATE_THUNK',
  async ({payload, callback}: EditTemplatePayload) => {
    try {
      let sortedTasks = cloneDeep(payload.tasks);
      sortTreesCreatedAtDESC(sortedTasks);
      sortedTasks = removeUnusedFields(sortedTasks);

      const response = await editTemplateAPI({
        id: payload.id,
        name: payload.name,
        description: payload.description,
        status: payload.status,
        tasks: sortedTasks,
      });

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Cập nhật thành công',
          description: 'Cập nhật checklist kiểm soát thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Cập nhật thất bại',
        description: error.response.data.message,
      });
    }
  },
);

export const deleteTemplateThunk = createAsyncThunk(
  'TEMPLATE_SLICE/DELETE_TEMPLATE_THUNK',
  async ({id, callback}: DeleteTemplatePayload) => {
    try {
      const response = await deleteTemplateAPI(id);

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Xoá thành công',
          description: 'Xoá template kiểm soát thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Xoá thất bại',
        description: error.response.data.message,
      });
    }
  },
);

export const editTemplateStatusThunk = createAsyncThunk(
  'TEMPLATE_SLICE/EDIT_TEMPLATE_STATUS_THUNK',
  async ({payload, callback}: EditTemplateStatusPayload) => {
    try {
      const response = await editTemplateStatusAPI({
        status: payload.status,
        templates: payload.templates.map(t => t.id),
      });

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Đổi trạng thái thành công',
          description: 'Đổi trạng thái checklist thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Đổi trạng thái thất bại',
        description: error.response.data.message,
      });
    }
  },
);
