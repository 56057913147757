import {Checkbox, Flex} from 'antd';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import {ColumnsType} from 'antd/es/table';

import {Text} from '~components';
import {LocalModules, ModuleKeys, Permission, PermissionActionKeys} from '~models';

interface GetColumns {
  permissions: Permission[];
  setFieldValue: (name: string, value: unknown) => void;
}

export const getPermissionColumns = ({
  permissions = [],
  setFieldValue,
}: GetColumns): ColumnsType<LocalModules> => {
  const handleUpdateForm = (event: CheckboxChangeEvent) => {
    const [module, action] = event.target.name?.split('__') as [ModuleKeys, PermissionActionKeys];
    const newPermission = {module, action};
    setFieldValue(
      'permissions',
      event.target.checked
        ? [...permissions, newPermission]
        : permissions.filter(p => !(p.module === module && p.action === action)),
    );
  };

  const renderModules = ({icon, title}: LocalModules) => {
    return (
      <Flex gap={10} align="center">
        {icon}
        <Text>{title}</Text>
      </Flex>
    );
  };

  const renderPermissionLabels = ({moduleKey, permissionLabels}: LocalModules) => {
    const permissionKeys = Object.keys(permissionLabels) as PermissionActionKeys[];

    return (
      <Flex vertical gap={20}>
        {permissionKeys.map(key => (
          <Flex key={key} gap={10}>
            <Checkbox
              name={`${moduleKey}__${key}`}
              onChange={handleUpdateForm}
              checked={permissions.some(p => p.module === moduleKey && p.action === key)}
            />
            <Text>{permissionLabels[key]}</Text>
          </Flex>
        ))}
      </Flex>
    );
  };

  return [
    {
      title: 'Modules',
      render: renderModules,
    },
    {
      title: 'Quyền',
      render: renderPermissionLabels,
    },
  ];
};
