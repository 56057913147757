import { ReactNode } from 'react';
import { Layout } from 'antd';

import { scrollRef } from '~hooks';

import './styles.scss';

interface Props {
  children?: ReactNode;
}

const Content = ({ children }: Props) => {
  return (
    <Layout.Content className="content" ref={scrollRef}>
      {children}
    </Layout.Content>
  );
};

export default Content;
