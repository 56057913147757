import { SVGAttributes } from 'react';

const PlusSquareIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x={0.5} y={0.5} width={27} height={27} rx={3.5} fill="#FAFAFA" stroke="#EEE" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 17.438c0 .239.198.437.438.437a.44.44 0 00.437-.438v-3.062h3.063a.44.44 0 00.437-.438.44.44 0 00-.438-.437h-3.062v-3.063a.44.44 0 00-.438-.437.44.44 0 00-.437.438V13.5h-3.063a.44.44 0 00-.437.438c0 .239.198.437.438.437H13.5v3.063z"
      fill="#292D32"
    />
  </svg>
);

export default PlusSquareIcon;
