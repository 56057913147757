import {Rule} from 'antd/es/form';

export interface AddGroupPermissionForm {
  name: string;
}

export const initialGroupPermissionValues: AddGroupPermissionForm = {
  name: '',
};

export const groupPermissionNameRules: Rule[] = [
  {
    required: true,
    message: 'Không được để trống',
  },
  {
    min: 5,
    message: 'Bạn phải nhập ít nhất 5 kí tự',
  },
];
