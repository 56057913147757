import { Status } from '~models';

export const statusOptions = [
  { value: Status.ACTIVE, label: 'Hoạt động' },
  { value: Status.INACTIVE, label: 'Ngừng hoạt động' },
];

export const statusLabel = {
  [Status.ACTIVE]: 'Hoạt động',
  [Status.INACTIVE]: 'Ngừng hoạt động',
};

export const defaultPageSizeOptions = [5, 10];
