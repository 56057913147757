import { Rule } from 'antd/es/form';

import { Task, TemplateStatus, TemplateType } from '~models';

export interface AddTemplateForm {
  name: string;
  description?: string;
  type?: TemplateType;
  status?: TemplateStatus;
  tasks: Task[];
}

export const initialValues: AddTemplateForm = {
  name: '',
  tasks: [],
};

export const nameRules: Rule[] = [
  {
    required: true,
    message: 'Không được để trống',
  },
];

export const typeRules: Rule[] = [
  {
    required: true,
    message: 'Không được để trống',
  },
];

export const statusRules: Rule[] = [
  {
    required: true,
    message: 'Không được để trống',
  },
];
