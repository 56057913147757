import {Flex, Tooltip} from 'antd';
import {ColumnsType} from 'antd/es/table';
import Highlighter from 'react-highlight-words';

import {DeleteIcon, EditIcon, EyeSquareIcon} from '~assets';
import {CommonObject, Pagination, Template, User} from '~models';
import {getSlug, showConfirmModal, showInfoModal} from '~utils';
import {AllPermission, templateStatusLabel} from '~variables';
import {navigate} from '~routes';
import {AppDispatch} from '~store';
import {deleteTemplateThunk} from '~reducers';

import {PreviewTemplate} from '../components';

interface GetColumns {
  user?: User;
  allowedPermissions: string[];
  searchTerm?: string;
  isPublicPage?: boolean;
  pagination: Pagination;
  templatesLength: number;
  dispatch: AppDispatch;
  setSearchParams: (values: CommonObject<string>) => void;
  getTemplates: () => void;
}

export const getTemplateColumns = ({
  user,
  allowedPermissions,
  searchTerm,
  isPublicPage,
  pagination,
  templatesLength,
  dispatch,
  setSearchParams,
  getTemplates,
}: GetColumns): ColumnsType<Template> => {
  const previewTemplate = (template: Template) => {
    showInfoModal({
      title: 'Xem trước checklist',
      content: <PreviewTemplate id={template.id} />,
    });
  };

  const editTemplate = (template: Template) => {
    navigate(`/template/add/${template.id}`);
  };

  const deleteTemplate = (template: Template) => {
    showConfirmModal({
      title: 'Xoá checklist',
      content: 'Xác nhận xoá checklist khỏi danh sách này',
      onOk: () => {
        dispatch(
          deleteTemplateThunk({
            id: template.id,
            callback: () => {
              if (pagination.current_page > 1 && templatesLength === 1) {
                setSearchParams({current_page: 1});
              } else getTemplates();
            },
          }),
        );
      },
    });
  };

  const renderIndex = (_: unknown, __: Template, index: number) => Number(pagination.from) + index;

  const renderName = (name: string) => (
    <Highlighter searchWords={[`${searchTerm}`]} textToHighlight={name} sanitize={getSlug} />
  );

  const renderStatus = (status: number) => templateStatusLabel[status];

  const renderCreatedBy = (_: unknown, template: Template) => {
    return `${template.created_by?.name} - ${template.department?.name}`;
  };

  const renderActions = (numSessions: number, template: Template) => {
    const canEdit =
      allowedPermissions.includes(AllPermission.Templates__Update) &&
      template.created_by?.username === user?.username;

    const canDelete =
      allowedPermissions.includes(AllPermission.Templates__Delete) &&
      template.created_by?.username === user?.username &&
      numSessions <= 0;

    return (
      <Flex className="icons" gap={8}>
        <Tooltip title="Xem trước" placement="bottomRight">
          <EyeSquareIcon className="icons__preview" onClick={() => previewTemplate(template)} />
        </Tooltip>
        {!isPublicPage && (
          <>
            {canEdit && (
              <Tooltip title="Sửa checklist" placement="bottomRight">
                <EditIcon className="icons__edit" onClick={() => editTemplate(template)} />
              </Tooltip>
            )}
            {canDelete && (
              <Tooltip title="Xoá checklist" placement="bottomRight">
                <DeleteIcon className="icons__delete" onClick={() => deleteTemplate(template)} />
              </Tooltip>
            )}
          </>
        )}
      </Flex>
    );
  };

  const columns: ColumnsType<Template> = [
    {
      title: 'STT',
      width: '6%',
      render: renderIndex,
    },
    {
      title: 'Tên checklist',
      dataIndex: 'name',
      key: 'name',
      render: renderName,
    },
    {
      title: 'Mô tả checklist',
      dataIndex: 'description',
      key: 'description',
      width: '30%',
    },
    {
      title: 'Người tạo',
      dataIndex: 'created_by',
      key: 'created_by',
      width: '15%',
      render: renderCreatedBy,
    },
    {
      title: 'Thao tác',
      dataIndex: 'num_sessions',
      key: 'num_sessions',
      width: '10%',
      render: renderActions,
    },
  ];

  if (!isPublicPage) {
    columns.splice(3, 0, {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: renderStatus,
    });
  }

  return columns;
};
