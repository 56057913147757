import {ReactElement} from 'react';
import {Navigate, RouteProps} from 'react-router-dom';

import {User} from '~models';

type Props = RouteProps & {
  user?: User;
};

const PublicRoute = ({user, element}: Props) => {
  return user ? <Navigate to="/" /> : (element as ReactElement);
};

export default PublicRoute;
