import {Layout, Tooltip} from 'antd';

import {Text} from '~components';
import {useAppSelector} from '~store';
import {DropdownIcon, ProfileIcon} from '~assets';
import {navigate} from '~routes';

import './styles.scss';

const Header = () => {
  const user = useAppSelector(state => state.authReducer.user);

  return (
    <Layout.Header className="header">
      <div className="user">
        <img className="user__image" width={40} height={40} src={user?.avatar} />
        <Tooltip
          title={
            <div className="profile__tooltip" onClick={() => navigate('/profile')}>
              <ProfileIcon className="profile__tooltip-icon" />
              <Text className="profile__tooltip-text">Hồ sơ cá nhân</Text>
            </div>
          }
          placement="topRight">
          <div className="user__text">
            <Text className="user__text-name">
              {user?.name} &nbsp; <DropdownIcon />
            </Text>
            <Text className="user__text-position">
              {user?.is_admin ? 'Quản trị viên' : 'Nhân viên'}
            </Text>
          </div>
        </Tooltip>
      </div>
    </Layout.Header>
  );
};

export default Header;
