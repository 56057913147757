import {HTMLAttributes, ReactNode} from 'react';

import './styles.scss';

interface Props extends HTMLAttributes<HTMLParagraphElement> {
  children: string | ReactNode;
  className?: string;
}

const Text = ({children, className, ...props}: Props) => {
  return (
    <p className={`text ${className}`} {...props}>
      {children}
    </p>
  );
};

export default Text;
