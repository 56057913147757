import queryString from 'query-string';

import {FORLIFE_HOME_URL} from '~configs';

export const goToForlifeHome = () => (window.location.href = FORLIFE_HOME_URL);

export const refreshAccessToken = () => {
  window.location.href = `${FORLIFE_HOME_URL}/login?redirect_url=${window.location.href}`;
};

export const getSearchParamsWindow = <T extends object>(): T => {
  return queryString.parse(window.location.search, {
    parseBooleans: true,
    parseNumbers: true,
  }) as T;
};

// export const setSearchParamsWindow = <T extends CommonObject<string>>(newParams: T) => {
//   const searchParams = getSearchParamsGlobal();

//   window.location.search = queryString.stringifyUrl(
//     {
//       url: '',
//       query: {
//         ...searchParams,
//         ...newParams,
//         ...(!newParams.current_page && { current_page: 1 }),
//       },
//     },
//     { skipEmptyString: true, skipNull: true },
//   );
// };
