import {PlusCircleOutlined} from '@ant-design/icons';
import {useMemo, useState} from 'react';

import {Button, PageHeader, Table} from '~components';
import {useGetData, usePermission} from '~hooks';
import {GroupPermission, GroupPermissionFilterParams, ModalMode} from '~models';
import {getGroupPermissionsThunk} from '~reducers';
import {AllPermission} from '~variables';

import {AddGroupPermissionModal} from './components';
import {getGroupPermissionColumns} from './utils';
import './styles.scss';

const GroupPermissionPage = () => {
  const [modalMode, setModalMode] = useState<ModalMode>();

  const {allowedPermissions} = usePermission();

  const {
    isLoading,
    data: groupPermissions,
    getData: getGroupPermissions,
    pagination,
    dispatch,
    setSearchParams,
  } = useGetData<GroupPermission, GroupPermissionFilterParams>(getGroupPermissionsThunk);

  const columns = useMemo(() => {
    return getGroupPermissionColumns({
      allowedPermissions,
      pagination,
      groupPermissionsLength: groupPermissions.length,
      dispatch,
      getGroupPermissions,
      setSearchParams,
    });
  }, [
    allowedPermissions,
    pagination,
    groupPermissions.length,
    dispatch,
    getGroupPermissions,
    setSearchParams,
  ]);

  return (
    <div className="group-permission">
      <PageHeader
        title="DANH SÁCH VAI TRÒ"
        actions={
          allowedPermissions.includes(AllPermission.Group_permissions__Create) && (
            <Button
              type="primary"
              size="large"
              icon={<PlusCircleOutlined />}
              onClick={() => setModalMode(ModalMode.ADD)}>
              Thêm mới
            </Button>
          )
        }
      />

      <Table
        className="group-permission__table"
        emptyText="Danh sách trống"
        loading={isLoading}
        columns={columns}
        dataSource={groupPermissions}
        pagination={{
          from: pagination.from,
          to: pagination.to,
          total: pagination.total,
          current: pagination.current_page,
          pageSize: pagination.per_page,
          onChange: current_page => setSearchParams({current_page}),
          onShowSizeChange: (_, per_page) => setSearchParams({per_page, current_page: 1}),
        }}
      />

      <AddGroupPermissionModal
        open={!!modalMode}
        setModalMode={setModalMode}
        getGroupPermissions={getGroupPermissions}
      />
    </div>
  );
};

export default GroupPermissionPage;
