import {Task} from '~models';

import {axiosClient} from './axios-client';
import {ListResponse, ObjectResponse, SuccessResponse} from './service-models';

interface AddTaskAPIBody {
  name: string;
  active: boolean;
  parent?: string;
  groups?: string[];
}

interface GetTasksAPIParams {
  search?: string;
  root_active?: boolean;
  full_active?: boolean;
  department?: string;
  page?: number;
  per_page?: number;
}

interface EditTaskAPIPayload {
  id: string;
  name: string;
  active: boolean;
  groups: string[];
}

export const addTaskAPI = (body: AddTaskAPIBody) => {
  return axiosClient.post<SuccessResponse>('/tasks', body);
};

export const editTaskAPI = ({id, ...body}: EditTaskAPIPayload) => {
  return axiosClient.patch<SuccessResponse>(`/tasks/${id}`, body);
};

export const getTasksAPI = (params: GetTasksAPIParams) => {
  return axiosClient.get<ListResponse<Task>>('/tasks', {params});
};

export const getTaskByIdAPI = (id: string) => {
  return axiosClient.get<ObjectResponse<Task>>(`/tasks/${id}`);
};

export const deleteTaskAPI = (id: string) => {
  return axiosClient.delete<SuccessResponse>(`/tasks/${id}`);
};
