import JSCookies, {CookieAttributes} from 'js-cookie';

const set = (key: string, value: unknown, options: CookieAttributes) => {
  const valueStr = typeof value === 'string' ? value : JSON.stringify(value);
  JSCookies.set(key, valueStr, options);
};

const get = (key: string) => JSCookies.get(key);

const remove = (key: string, options: CookieAttributes) => JSCookies.remove(key, options);

export const Cookies = {set, get, remove};
