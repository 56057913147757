import {Form, ModalProps} from 'antd';

import {Modal} from '~components';
import {useAppDispatch} from '~store';
import {addEmployeesThunk} from '~reducers';
import {ModalMode} from '~models';

import SelectMultiEmployee from './SelectMultiEmployee';
import {AddEmployeeForm, employeeRules, initialEmployeeValues} from '../utils';

interface Props extends ModalProps {
  groupPermissionId: string;
  setModalMode: (mode?: ModalMode) => void;
  getEmployees: () => void;
}

const AddEmployeeModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<AddEmployeeForm>();

  const {groupPermissionId, setModalMode, getEmployees} = props;
  const {submit, resetFields} = form;

  const handleSubmit = ({employees}: AddEmployeeForm) => {
    dispatch(
      addEmployeesThunk({
        payload: {
          groupPermissionId,
          employees,
        },
        callback: () => {
          setModalMode(undefined);
          getEmployees();
        },
      }),
    );
  };

  return (
    <Modal
      width={600}
      title="Thêm mới"
      cancelText="Huỷ"
      okText="Thêm mới"
      onOk={submit}
      onCancel={() => setModalMode(undefined)}
      afterClose={resetFields}
      {...props}>
      <Form form={form} initialValues={initialEmployeeValues} onFinish={handleSubmit}>
        <Form.Item name="employees" rules={employeeRules}>
          <SelectMultiEmployee />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEmployeeModal;
