import {createSlice} from '@reduxjs/toolkit';

interface GroupPermissionState {}

const initialState: GroupPermissionState = {};

const groupPermissionSlice = createSlice({
  name: 'GROUP_PERMISSION_SLICE',
  initialState,
  reducers: {},
});

export const groupPermissionReducer = groupPermissionSlice.reducer;
export * from './async-actions';
