import {useEffect} from 'react';
import {Form, ModalProps} from 'antd';

import {Input, Modal} from '~components';
import {addTaskGroupThunk, editTaskGroupThunk} from '~reducers';
import {TaskGroup} from '~models';
import {useAppDispatch} from '~store';
import {getTaskGroupByIdAPI} from '~services';

import {AddTaskGroupForm, initialValues, nameRules} from '../../utils';
import './styles.scss';

interface Props extends ModalProps {
  setIsModalOpen: (value: boolean) => void;
  getTaskGroups: () => void;
  selectedTaskGroupId: string;
  setSelectedTaskGroupId: (value: string) => void;
}

const AddTaskGroupModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<AddTaskGroupForm>();

  const {selectedTaskGroupId, getTaskGroups, setIsModalOpen, setSelectedTaskGroupId} = props;
  const {submit, resetFields, setFieldValue} = form;

  const hideModal = () => {
    setSelectedTaskGroupId('');
    setIsModalOpen(false);
  };

  const onAddSuccess = () => {
    hideModal();
    getTaskGroups();
  };

  const handleSubmit = ({name}: AddTaskGroupForm) => {
    if (selectedTaskGroupId) {
      dispatch(
        editTaskGroupThunk({
          payload: {
            id: selectedTaskGroupId,
            name,
          },
          callback: onAddSuccess,
        }),
      );
    } else {
      dispatch(
        addTaskGroupThunk({
          name,
          callback: onAddSuccess,
        }),
      );
    }
  };

  useEffect(() => {
    if (selectedTaskGroupId) {
      getTaskGroupByIdAPI(selectedTaskGroupId).then(response => {
        const selectedTaskGroup: TaskGroup = response.data.data;
        setFieldValue('name', selectedTaskGroup.name);
      });
    }
  }, [selectedTaskGroupId, setFieldValue]);

  return (
    <Modal
      width={700}
      className="add-task-group-modal"
      title="Tạo mới nhóm lỗi tham chiếu"
      cancelText="Huỷ"
      okText={selectedTaskGroupId ? 'Lưu' : 'Thêm mới'}
      onOk={submit}
      onCancel={hideModal}
      afterClose={resetFields}
      {...props}>
      <Form form={form} onFinish={handleSubmit} initialValues={initialValues}>
        <Form.Item name="name" rules={nameRules}>
          <Input label="Tên nhóm lỗi tham chiếu" placeholder="Tên nhóm lỗi tham chiếu" required />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddTaskGroupModal;
