import {Form, ModalProps} from 'antd';

import {Input, Modal} from '~components';
import {addGroupPermissionThunk} from '~reducers';
import {useAppDispatch} from '~store';
import {ModalMode} from '~models';

import {
  AddGroupPermissionForm,
  initialGroupPermissionValues,
  groupPermissionNameRules,
} from '../utils';

interface Props extends ModalProps {
  setModalMode: (mode?: ModalMode) => void;
  getGroupPermissions: () => void;
}

const AddGroupPermissionModal = ({setModalMode, getGroupPermissions, ...props}: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<AddGroupPermissionForm>();

  const {submit, resetFields} = form;

  const handleSubmit = ({name}: AddGroupPermissionForm) => {
    dispatch(
      addGroupPermissionThunk({
        name,
        callback: () => {
          setModalMode(undefined);
          getGroupPermissions();
        },
      }),
    );
  };

  return (
    <Modal
      width={600}
      title="Tạo mới vai trò"
      cancelText="Huỷ"
      okText="Thêm mới"
      onOk={submit}
      onCancel={() => setModalMode(undefined)}
      afterClose={resetFields}
      {...props}>
      <Form form={form} initialValues={initialGroupPermissionValues} onFinish={handleSubmit}>
        <Form.Item name="name" rules={groupPermissionNameRules}>
          <Input label="Tên vai trò" placeholder="Nhập tên vai trò" required />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddGroupPermissionModal;
