import {Base, CommonObject} from '~models';

export const cloneDeep = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const classNames = (...classes: (string | CommonObject<string>)[]): string => {
  return classes
    .map(cls => {
      if (typeof cls === 'string') return cls;
      return Object.keys(cls).filter(key => !!cls[key]);
    })
    .flat(Infinity)
    .join(' ');
};

export const convertToOption = <O extends Base>(obj: O) => ({
  ...obj,
  label: obj.name,
  value: obj.id,
});
