import {HttpStatusCode} from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {getUserProfileAPI} from '~services';

import {setAuthUserAction} from '.';

export const getAuthUserThunk = createAsyncThunk(
  'AUTH_SLICE/GET_AUTH_USER_THUNK',
  async (_, {dispatch}) => {
    try {
      const response = await getUserProfileAPI();

      if (response.status === HttpStatusCode.Ok) {
        dispatch(setAuthUserAction(response.data.data));
      }
    } catch (error) {
      console.log(error);
    }
  },
);
