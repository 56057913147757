import {useRef} from 'react';

export const useDebounceFunction = <T>(func: (ev: T) => void, delay?: number) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const debouncedFunc = (ev: T) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => func(ev), delay || 500);
  };

  return debouncedFunc;
};
