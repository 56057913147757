import {ChecklistIcon, DotIcon, GroupPermissionIcon, TaskGroupIcon, TaskIcon} from '~assets';

import {AllPermission} from './permission';

export const menuItems = [
  {
    key: '/',
    icon: <TaskIcon />,
    label: 'Nội dung kiểm soát',
    permission: AllPermission.Tasks__View,
  },
  {
    key: '/template',
    icon: <ChecklistIcon />,
    label: 'Checklist kiểm soát',
    permission: AllPermission.Templates__View,
    children: [
      {
        key: '/template?category=0',
        icon: (
          <div className="dot-icon dot-icon--top">
            <DotIcon />
          </div>
        ),
        label: 'Checklist của tôi',
      },
      {
        key: '/template?category=1',
        icon: (
          <div className="dot-icon dot-icon--bottom">
            <DotIcon />
          </div>
        ),
        label: 'Đã ban hành',
      },
    ],
  },
  {
    key: '/task-group',
    icon: <TaskGroupIcon />,
    label: 'Nhóm lỗi tham chiếu',
    permission: AllPermission.Task_groups__View,
  },
  {
    key: '/group-permission',
    icon: <GroupPermissionIcon />,
    label: 'Phân quyền',
    permission: AllPermission.Group_permissions__View,
  },
];
