import {Base} from './common';
import {Department} from './department';
import {Task} from './task';
import {User} from './user';

export interface TemplateType extends Base {}

export enum TemplateStatus {
  DRAFT = 0,
  INTERNAL_DEPARTMENT = 1,
  ALL = 2,
}

export interface Template extends Base {
  type?: TemplateType;
  department?: Department;
  status?: TemplateStatus;
  created_by?: User;
  tasks: Task[];
  description?: string;
  num_sessions?: number;
}

export enum TemplateCategory {
  MINE = 0,
  PUBLIC = 1,
}

export interface TemplateFilterParams {
  search?: string;
  category?: TemplateCategory;
  created_by?: string;
  status?: TemplateStatus;
  deletable?: number;
  type?: string;
  current_page?: number;
  per_page?: number;
  department?: string;
}

export enum TemplateAction {
  CHANGE_STATUS = 0,
}
