import {Employee} from '~models';

import {axiosClient} from './axios-client';
import {ListResponse, SuccessResponse} from './service-models';

interface GetEmployeesAPIPayload {
  groupPermissionId: string;
  search?: string;
  page?: number;
  per_page?: number;
}

interface AddEmployeesAPIPayload {
  groupPermissionId: string;
  users: string[];
}

interface DeleteEmployeesAPIPayload {
  groupPermissionId: string;
  users: string[];
}

export const getEmployeesAPI = ({groupPermissionId, ...params}: GetEmployeesAPIPayload) => {
  return axiosClient.get<ListResponse<Employee>>(`/group-permissions/${groupPermissionId}/users`, {
    params,
  });
};

export const addEmployeesAPI = ({groupPermissionId, ...body}: AddEmployeesAPIPayload) => {
  return axiosClient.post<SuccessResponse>(`/group-permissions/${groupPermissionId}/users`, body);
};

export const deleteEmployeesAPI = ({groupPermissionId, ...data}: DeleteEmployeesAPIPayload) => {
  return axiosClient.delete<SuccessResponse>(`/group-permissions/${groupPermissionId}/users`, {
    data,
  });
};
