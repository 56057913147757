import { Rule } from 'antd/es/form';

export interface AddTaskGroupForm {
  name: string;
}

export const initialValues: AddTaskGroupForm = {
  name: '',
};

export const nameRules: Rule[] = [
  {
    required: true,
    message: 'Không được để trống',
  },
];
