import {useEffect, useMemo, useState} from 'react';

import {getMenuOpenKeys, getQueryTemplateCategory} from '~utils';

import {useMemoLocation} from './useMemoLocation';

interface Options {
  collapsed: boolean;
}

export const useMenu = ({collapsed}: Options) => {
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const {pathname, search} = useMemoLocation();

  useEffect(() => {
    if (!collapsed) {
      const currOpenKeys = getMenuOpenKeys(pathname);
      setOpenKeys(currOpenKeys);
    }
  }, [collapsed, pathname]);

  useEffect(() => {
    const selectedKey = pathname.includes('template')
      ? pathname + getQueryTemplateCategory(search)
      : pathname;
    setSelectedKeys([selectedKey]);
  }, [pathname, search]);

  return useMemo(
    () => ({
      openKeys,
      setOpenKeys,
      selectedKeys,
    }),
    [openKeys, selectedKeys],
  );
};
