import {FloatButton, Form} from 'antd';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {ArrowTopIcon, EyeIcon, SaveIcon} from '~assets';
import {useAppDispatch} from '~store';
import {addTemplateThunk, editTemplateThunk} from '~reducers';
import {Button, PageHeader} from '~components';
import {getTemplateById} from '~services';
import {useScroll} from '~hooks';
import {navigate} from '~routes';
import {isNullOrUndefined, showInfoModal} from '~utils';

import {AddTemplateForm, initialValues} from '../utils';
import {CheckListInfo, PreviewTemplate, SelectTasks} from '../components';

const AddTemplate = () => {
  const [canPreview, setCanPreview] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const {showBackTop, scrollToTop} = useScroll({});
  const {templateId = ''} = useParams();
  const [form] = Form.useForm<AddTemplateForm>();
  const formValues = Form.useWatch([], form) || {};

  const {submit, getFieldsValue, setFieldsValue} = form;

  const goToMyTemplatePage = () => {
    navigate('/template?category=0');
  };

  const previewTemplate = useCallback(() => {
    const values = getFieldsValue();
    showInfoModal({
      title: 'Xem trước checklist',
      content: <PreviewTemplate viewedTemplate={{id: '', ...values}} />,
    });
  }, [getFieldsValue]);

  const handleSubmit = (values: AddTemplateForm) => {
    if (templateId) {
      dispatch(
        editTemplateThunk({
          payload: {
            ...values,
            id: templateId,
          },
          callback: goToMyTemplatePage,
        }),
      );
    } else {
      dispatch(
        addTemplateThunk({
          payload: values,
          callback: goToMyTemplatePage,
        }),
      );
    }
  };

  useEffect(() => {
    const newCanPreview = !!(
      formValues.name &&
      formValues.type &&
      !isNullOrUndefined(formValues.status)
    );
    setCanPreview(newCanPreview);
  }, [formValues.name, formValues.type, formValues.status]);

  useEffect(() => {
    if (templateId) {
      getTemplateById(templateId).then(response => {
        const template = response.data.data;

        setFieldsValue({
          name: template.name,
          description: template.description,
          status: template.status,
          type: template.type,
          tasks: template.tasks,
        });
      });
    }
  }, [setFieldsValue, templateId]);

  const headerActions = useMemo(() => {
    return (
      <>
        <Button size="large" icon={<EyeIcon />} disabled={!canPreview} onClick={previewTemplate}>
          Xem trước checklist
        </Button>
        <Button type="primary" size="large" icon={<SaveIcon />} onClick={submit}>
          Lưu checklist
        </Button>
      </>
    );
  }, [canPreview, previewTemplate, submit]);

  return (
    <div className="add-template">
      <PageHeader
        title={`${templateId ? 'CHI TIẾT' : 'TẠO'} CHECKLIST`}
        backUrl="/template?category=0"
        actions={headerActions}
      />
      <Form form={form} initialValues={initialValues} onFinish={handleSubmit}>
        <CheckListInfo />
        <SelectTasks />
      </Form>
      {showBackTop && <FloatButton icon={<ArrowTopIcon />} onClick={scrollToTop} />}
    </div>
  );
};

export default AddTemplate;
