import React, { SVGAttributes } from 'react';

const DotIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={5}
    height={5}
    viewBox="0 0 5 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx={2.5} cy={2.5} r={2.5} fill="#fff" />
  </svg>
);

export default DotIcon;
