import { RefObject, useCallback, useEffect, useState } from 'react';

import { useDebounceFunction } from './useDebounceFunction';

const TABLE_HEADER_HEIGHT = 55.45;
const TABLE_FOOTER_HEIGHT = 58;
const TABLE_BODY_EMPTY_HEIGHT = 166.45;

export const useTableHeight = (ref: RefObject<Element>) => {
  const [tableHeight, setTableHeight] = useState<number>(TABLE_BODY_EMPTY_HEIGHT);

  const resizeTable = useCallback(
    useDebounceFunction(() => {
      if (ref.current) {
        const { height } = ref.current.getBoundingClientRect();
        setTableHeight(height - TABLE_HEADER_HEIGHT - TABLE_FOOTER_HEIGHT);
      }
    }, 400),
    [],
  );

  useEffect(() => {
    resizeTable({});
    window.addEventListener('resize', resizeTable);

    return () => {
      window.removeEventListener('resize', resizeTable);
    };
  }, [resizeTable]);

  return tableHeight;
};
