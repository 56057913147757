import { createSlice } from '@reduxjs/toolkit';

interface TaskState {
  // isLoading: boolean;
  // tasks: Task[];
  // pagination: Pagination;
  // filter: TaskFilter;
}

const initialState: TaskState = {
  // isLoading: false,
  // tasks: [],
  // pagination: {
  //   current_page: 1,
  //   per_page: 10,
  //   from: 1,
  // },
  // filter: {},
};

const taskSlice = createSlice({
  name: 'TASK_SLICE',
  initialState,
  reducers: {
    // setTaskIsLoadingAction: (state, { payload }: PayloadAction<boolean>) => {
    //   state.isLoading = payload;
    // },
    // setTasksAction: (state, { payload }: PayloadAction<ListPagination<Task>>) => {
    //   state.tasks = payload.data;
    //   state.pagination = payload.pagination;
    // },
    // setTaskFilterAction: (state, { payload }: PayloadAction<TaskFilter>) => {
    //   state.filter = { ...state.filter, ...payload };
    //   if (state.pagination.current_page !== 1) state.pagination.current_page = 1;
    //   // if (state.tasks.length) state.tasks = [];
    // },
    // setTaskPaginationAction: (state, { payload }: PayloadAction<Partial<Pagination>>) => {
    //   state.pagination = {
    //     ...state.pagination,
    //     ...payload,
    //     ...(payload.per_page && { current_page: 1 }),
    //   };
    //   // if (state.tasks.length) state.tasks = [];
    // },
    // resetTaskFilterAction: state => {
    //   state.filter = initialState.filter;
    //   state.pagination = initialState.pagination;
    //   state.tasks = initialState.tasks;
    // },
  },
});

export const taskReducer = taskSlice.reducer;
export * from './async-actions';
// export const {
//   setTasksAction,
//   setTaskIsLoadingAction,
//   setTaskPaginationAction,
//   resetTaskFilterAction,
//   setTaskFilterAction,
// } = taskSlice.actions;
