import {useEffect, useMemo, useState} from 'react';
import {Flex} from 'antd';

import {Input, Select, Text, TextArea, TreeTable} from '~components';
import {Pagination, Template} from '~models';
import {getTemplateById} from '~services';
import {cloneDeep, convertToOption, sortTreesCreatedAtDESC} from '~utils';

import {getTaskColumns} from '../../utils';
import './styles.scss';

interface Props {
  id?: string;
  viewedTemplate?: Template;
}

const PreviewTemplate = ({id, viewedTemplate}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [template, setTemplate] = useState<Template>();
  const [pagination, setPagination] = useState<Pagination>({
    from: 1,
    to: 5,
    current_page: 1,
    per_page: 5,
  });

  const templateTasks = template?.tasks.slice(pagination.from - 1, pagination.to);
  const templateTypeOptions = (template?.type ? [template.type] : []).map(convertToOption);

  const handlePageChange = (page: number) => {
    const fromItem = (page - 1) * pagination.per_page + 1;
    const toItem = page * pagination.per_page;
    setPagination({
      ...pagination,
      current_page: page,
      from: fromItem,
      to: toItem,
    });
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getTemplateById(id)
        .then(response => {
          setTemplate(response.data.data);
          setPagination(pagination => ({
            ...pagination,
            total: response.data.data.tasks.length,
          }));
        })
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  useEffect(() => {
    if (viewedTemplate) {
      const sortedTasks = cloneDeep(viewedTemplate.tasks);
      sortTreesCreatedAtDESC(sortedTasks);

      setTemplate({
        ...viewedTemplate,
        tasks: sortedTasks,
      });
      setPagination(pagination => ({
        ...pagination,
        total: viewedTemplate.tasks.length,
      }));
    }
  }, [viewedTemplate]);

  const columns = useMemo(() => getTaskColumns({searchTerm: ''}), []);

  return (
    <Flex className="preview" vertical>
      <Flex vertical gap={16}>
        <Flex justify="space-between" gap={16}>
          <Input className="preview__name" label="Tên checklist" value={template?.name} disabled />
          <Select
            className="preview__description"
            label="Loại checklist"
            value={template?.type?.id}
            options={templateTypeOptions}
            disabled
          />
        </Flex>
        <TextArea
          className="preview__description"
          label="Mô tả checklist"
          autoSize={{minRows: 2, maxRows: 4}}
          value={template?.description}
          disabled
        />
      </Flex>
      <Text className="preview-table__title">Danh sách nội dung trong checklist</Text>
      <TreeTable
        className="preview__table"
        emptyText="Danh sách trống"
        bordered
        size="small"
        loading={isLoading}
        columns={columns}
        dataSource={templateTasks}
        pagination={{
          hideTotal: true,
          hideSizeChanger: true,
          from: pagination.from,
          total: pagination.total,
          current: pagination.current_page,
          pageSize: pagination.per_page,
          onChange: handlePageChange,
        }}
      />
    </Flex>
  );
};

export default PreviewTemplate;
