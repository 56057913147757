import {createAsyncThunk} from '@reduxjs/toolkit';
import {notification} from 'antd';
import {HttpStatusCode} from 'axios';

import {GroupPermissionFilterParams} from '~models';
import {getSearchParamsWindow} from '~utils';
import {
  addGroupPermissionAPI,
  deleteGroupPermissionAPI,
  editGroupPermissionAPI,
  getGroupPermissionsAPI,
} from '~services';

import {getAuthUserThunk} from '../auth';
import {
  AddGroupPermissionPayload,
  DeleteGroupPermissionPayload,
  EditGroupPermissionPayload,
  GetGroupPermissionsPayload,
} from './action-models';

export const addGroupPermissionThunk = createAsyncThunk(
  'GROUP_PERMISSION_SLICE/ADD_GROUP_PERMISSION_THUNK',
  async ({name, callback}: AddGroupPermissionPayload) => {
    try {
      const response = await addGroupPermissionAPI(name);

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Thêm mới thành công',
          description: 'Thêm mới vai trò thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Thêm mới thất bại',
        description: error.response.data.message,
      });
    }
  },
);

export const getGroupPermissionsThunk = createAsyncThunk(
  'GROUP_PERMISSION_SLICE/GET_GROUP_PERMISSIONS_THUNK',
  async ({setIsLoading, callback}: GetGroupPermissionsPayload) => {
    try {
      setIsLoading(true);
      const searchParams = getSearchParamsWindow<GroupPermissionFilterParams>();

      const response = await getGroupPermissionsAPI({
        page: searchParams.current_page,
        per_page: searchParams.per_page,
      });

      if (response.status === HttpStatusCode.Ok) {
        callback(response.data.data);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  },
);

export const editGroupPermissionThunk = createAsyncThunk(
  'GROUP_PERMISSION_SLICE/EDIT_GROUP_PERMISSION_THUNK',
  async ({payload, callback}: EditGroupPermissionPayload, {dispatch}) => {
    try {
      const response = await editGroupPermissionAPI(payload);

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Cập nhật thành công',
          description: 'Cập nhật vai trò thành công.',
        });

        dispatch(getAuthUserThunk());
        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Cập nhật thất bại',
        description: error.response.data.message,
      });
    }
  },
);

export const deleteGroupPermissionThunk = createAsyncThunk(
  'GROUP_PERMISSION_SLICE/DELETE_GROUP_PERMISSION_THUNK',
  async ({id, callback}: DeleteGroupPermissionPayload) => {
    try {
      const response = await deleteGroupPermissionAPI(id);

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Xoá thành công',
          description: 'Xoá vai trò thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Xoá thất bại',
        description: error.response.data.message,
      });
    }
  },
);
