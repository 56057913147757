import {ReactElement} from 'react';
import {Navigate, RouteProps} from 'react-router-dom';

import {User} from '~models';

type Props = RouteProps & {
  user?: User;
};

const PrivateRoute = ({user, element}: Props) => {
  return user ? (element as ReactElement) : <Navigate to="/login" />;
};

export default PrivateRoute;
