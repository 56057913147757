import {ColumnsType} from 'antd/es/table';
import {Flex, Tooltip} from 'antd';
import Highlighter from 'react-highlight-words';

import {CommonObject, Pagination, TaskGroup, User} from '~models';
import {DeleteIcon, EditIcon} from '~assets';
import {getSlug, showConfirmModal} from '~utils';
import {AppDispatch} from '~store';
import {deleteTaskGroupThunk} from '~reducers';
import {AllPermission} from '~variables';

interface GetColumns {
  user?: User;
  dispatch: AppDispatch;
  searchTerm?: string;
  pagination: Pagination;
  taskGroupsLength: number;
  setSearchParams: (values: CommonObject<string>) => void;
  getTaskGroups: () => void;
  allowedPermissions: string[];
  setIsModalOpen: (value: boolean) => void;
  setSelectedTaskGroupId: (taskId: string) => void;
}

export const getColumns = ({
  user,
  allowedPermissions,
  searchTerm,
  dispatch,
  pagination,
  taskGroupsLength,
  setSearchParams,
  getTaskGroups,
  setIsModalOpen,
  setSelectedTaskGroupId,
}: GetColumns): ColumnsType<TaskGroup> => {
  const editTaskGroup = (taskGroup: TaskGroup) => {
    setIsModalOpen(true);
    setSelectedTaskGroupId(taskGroup.id);
  };

  const deleteTaskGroup = (taskGroup: TaskGroup) => {
    showConfirmModal({
      title: 'Xoá nhóm lỗi tham chiếu',
      content: 'Sau khi xác nhận xóa bạn sẽ không thể hoàn tác',
      onOk: () => {
        dispatch(
          deleteTaskGroupThunk({
            id: taskGroup.id,
            callback: () => {
              if (pagination.current_page > 1 && taskGroupsLength === 1) {
                setSearchParams({current_page: 1});
              } else {
                getTaskGroups();
              }
            },
          }),
        );
      },
    });
  };

  const renderActions = (deletable: boolean, taskGroup: TaskGroup) => {
    const canDelete =
      allowedPermissions.includes(AllPermission.Task_groups__Delete) &&
      taskGroup.created_by?.username === user?.username;

    const canEdit =
      allowedPermissions.includes(AllPermission.Task_groups__Update) &&
      taskGroup.created_by?.username === user?.username;

    return (
      <Flex className="icons" gap={8}>
        {canEdit && (
          <Tooltip title="Chỉnh sửa" placement="bottomRight">
            <EditIcon className="icons__edit" onClick={() => editTaskGroup(taskGroup)} />
          </Tooltip>
        )}
        {canDelete && deletable && (
          <Tooltip title="Xoá" placement="bottomRight">
            <DeleteIcon className="icons__delete" onClick={() => deleteTaskGroup(taskGroup)} />
          </Tooltip>
        )}
      </Flex>
    );
  };

  const renderName = (name: string) => (
    <Highlighter searchWords={[String(searchTerm)]} textToHighlight={name} sanitize={getSlug} />
  );

  return [
    {
      title: 'Nhóm lỗi tham chiếu',
      dataIndex: 'name',
      key: 'name',
      render: renderName,
    },
    {
      title: 'Thao tác',
      dataIndex: 'deletable',
      key: 'deletable',
      width: '10%',
      render: renderActions,
    },
  ];
};
