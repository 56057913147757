import { ReactNode } from 'react';
import { Modal as AntdModal, ModalProps } from 'antd';

import Text from '../Text';
import './styles.scss';

interface Props extends ModalProps {
  children: string | ReactNode;
}

const Modal = ({ children, title, className, ...props }: Props) => {
  return (
    <AntdModal
      className={`modal ${className}`}
      title={<Text className="modal__title">{title}</Text>}
      {...props}>
      {children}
    </AntdModal>
  );
};

export default Modal;
