import {Flex} from 'antd';

import {loadingImg} from '~assets';

const Loading = () => {
  return (
    <Flex align="center" justify="center">
      <img src={loadingImg} />
    </Flex>
  );
};

export default Loading;
