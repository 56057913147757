import {useMemo, useState} from 'react';
import {Flex, Layout} from 'antd';

import {Text} from '~components';
import {SiderContext} from '~contexts';
import {useAppDispatch} from '~store';
import {resetAuthAction} from '~reducers';
import {classNames, goToForlifeHome} from '~utils';
import {
  BackIcon,
  ChecklistLogoIcon,
  LogoutIcon,
  MenuIcon,
  FlowerIcon,
  FlowerSmallIcon,
} from '~assets';

import SiderMenu from './SiderMenu';
import './styles.scss';

const Sider = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const siderStyle = useMemo(() => {
    return {
      backgroundImage: `url('${collapsed ? FlowerSmallIcon : FlowerIcon}')`,
      backgroundSize: 'cover',
    };
  }, [collapsed]);

  return (
    <Layout.Sider className="sider" width={256} style={siderStyle} collapsed={collapsed}>
      <SiderContext.Provider value={{collapsed}}>
        <Flex vertical className="sider__content">
          <Flex align="center" className="back">
            <BackIcon
              fill="white"
              className={classNames('back__icon', {
                'back__icon--collapsed': collapsed,
              })}
              onClick={goToForlifeHome}
            />
            {!collapsed && <Text className="back__title">Forlife Home</Text>}
          </Flex>

          <Flex
            align="center"
            className={classNames('header', {
              'header--collapsed': collapsed,
            })}>
            <MenuIcon className="header__menu-icon" onClick={() => setCollapsed(!collapsed)} />
            {!collapsed && (
              <>
                <ChecklistLogoIcon className="header__checklist-icon" />
                <Text className="header__title">APP KIỂM SOÁT</Text>
              </>
            )}
          </Flex>

          <SiderMenu />

          <Flex
            align="center"
            className={classNames('logout', {
              'logout--collapsed': collapsed,
            })}>
            <LogoutIcon className="logout__icon" onClick={() => dispatch(resetAuthAction())} />
            {!collapsed && <Text className="logout__text">Đăng xuất</Text>}
          </Flex>
        </Flex>
      </SiderContext.Provider>
    </Layout.Sider>
  );
};

export default Sider;
