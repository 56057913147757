import {createContext, useContext} from 'react';

interface SiderContextState {
  collapsed: boolean;
}

export const SiderContext = createContext<SiderContextState | null>(null);

export const useSiderContext = () => {
  const context = useContext(SiderContext);

  if (!context) {
    throw new Error('useSiderContext must be used within SiderContext.Provider');
  }

  return context;
};
