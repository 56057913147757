import {Form} from 'antd';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Select} from '~components';
import {TemplateType} from '~models';
import {getTemplateTypeAPI} from '~services';
import {convertToOption} from '~utils';

import {AddTemplateForm} from '../../utils';

const SelectTemplateType = () => {
  const [templateTypes, setTemplateTypes] = useState<TemplateType[]>([]);

  const {templateId} = useParams();
  const formInstance = Form.useFormInstance<AddTemplateForm>();
  const templateType = Form.useWatch('type', formInstance);

  const {setFieldValue} = formInstance;
  const templateTypesOptions = templateTypes.map(convertToOption);

  const handleSelect = (value: string) => {
    const type = templateTypes.find(type => type.id === value);
    setFieldValue('type', type);
  };

  useEffect(() => {
    getTemplateTypeAPI().then(response => {
      setTemplateTypes(response.data.data.data);
    });
  }, [setTemplateTypes]);

  return (
    <Select
      label="Loại checklist"
      placeholder="---Chọn loại checklist---"
      required
      value={templateType?.id}
      disabled={!!templateId}
      options={templateTypesOptions}
      onSelect={handleSelect}
    />
  );
};

export default SelectTemplateType;
