import { Department } from '~models';

import { axiosClient } from './axios-client';
import { ListResponse, ObjectResponse } from './service-models';

interface GetDepartmentsAPIParams {
  search?: string;
  page?: number;
  per_page?: number;
}

export const getDepartmentsAPI = (params: GetDepartmentsAPIParams) => {
  return axiosClient.get<ListResponse<Department>>('/departments', { params });
};

export const getDepartmentByIdAPI = (id: string) => {
  return axiosClient.get<ObjectResponse<Department>>(`/departments/${id}`);
};
