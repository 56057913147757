import {ChangeEvent} from 'react';

import {Input} from '~components';
import {useDebounceFunction} from '~hooks';
import {EmployeeFilterParams} from '~models';

import './styles.scss';

interface Props {
  searchParams: EmployeeFilterParams;
  setSearchParams: (values: EmployeeFilterParams) => void;
}

const EmployeeFilter = ({searchParams, setSearchParams}: Props) => {
  const handleSearchChange = useDebounceFunction<ChangeEvent<HTMLInputElement>>(event => {
    setSearchParams({search: event.target.value});
  });

  return (
    <div className="employee-filter">
      <Input
        className="field"
        placeholder="Nhập mã, họ tên"
        label="Tìm kiếm theo mã, họ tên"
        horizontal
        allowClear
        defaultValue={searchParams.search}
        onChange={handleSearchChange}
      />
    </div>
  );
};

export default EmployeeFilter;
