import {ChecklistIcon, GroupPermissionIcon, TaskGroupIcon, TaskIcon} from '~assets';
import {LocalModules} from '~models';

export const localModules: LocalModules[] = [
  {
    id: 'noi-dung-kiem-soat',
    title: 'Nội dung kiểm soát',
    icon: <TaskIcon fill="#333" />,
    moduleKey: 'tasks',
    permissionLabels: {
      create: 'Tạo nội dung kiểm soát',
      view: 'Xem nội dung kiểm soát',
      update: 'Cập nhật nội dung kiểm soát',
      delete: 'Xóa nội dung kiểm soát',
    },
  },
  {
    id: 'checklist-kiem-soat',
    title: 'Checklist kiểm soát',
    icon: <ChecklistIcon fill="#333" size={20} />,
    moduleKey: 'templates',
    permissionLabels: {
      create: 'Tạo checklist kiểm soát',
      view: 'Xem checklist kiểm soát',
      update: 'Cập nhật checklist kiểm soát',
      delete: 'Xóa checklist kiểm soát',
    },
  },
  {
    id: 'nhom-loi-tham-chieu',
    title: 'Nhóm lỗi tham chiếu',
    icon: <TaskGroupIcon fill="#333" size={22} />,
    moduleKey: 'task_groups',
    permissionLabels: {
      create: 'Tạo nhóm lỗi tham chiếu',
      view: 'Xem nhóm lỗi tham chiếu',
      update: 'Cập nhật nhóm lỗi tham chiếu',
      delete: 'Xóa nhóm lỗi tham chiếu',
    },
  },
  {
    id: 'phan-quyen',
    title: 'Phân quyền',
    icon: <GroupPermissionIcon fill="#333" size={24} />,
    moduleKey: 'group_permissions',
    permissionLabels: {
      create: 'Tạo vai trò',
      view: 'Xem vai trò',
      update: 'Cập nhật vai trò',
      delete: 'Xóa vai trò',
    },
  },
  {
    id: 'phien',
    title: 'Phiên',
    icon: <ChecklistIcon fill="#333" size={20} />,
    moduleKey: 'sessions',
    permissionLabels: {
      supervisor: 'Xử lý phiên',
      resolver: 'Phản hồi phiên',
      manager: 'Đề xuất xem xét',
      viewer: 'Xem phiên được tiếp nhận',
      view_as_supervisor: 'Xem phiên của bộ phận mình',
      view_as_resolver: 'Xem phiên được tạo tới bộ phận mình',
    },
  },
];
