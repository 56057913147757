import { SVGAttributes } from 'react';

const ProfileIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={18}
    height={17}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 .271c4.591 0 8.333 3.742 8.333 8.334a8.288 8.288 0 01-2.7 6.117c.005.004.007.006.008.009v.007c-.083.075-.175.15-.266.225a.9.9 0 00-.096.077l-.046.04a7.076 7.076 0 01-.526.395c-.038.029-.075.056-.116.08a8.067 8.067 0 01-.333.202l-.167.098a1.826 1.826 0 00-.067.04 1.408 1.408 0 01-.116.068c-.175.092-.35.175-.525.25-.067.034-.133.067-.2.092-.117.05-.24.092-.365.135l-.185.065c-.067.017-.133.042-.2.067-.141.049-.292.084-.441.12l-.16.038a.701.701 0 00-.1.022c-.028.008-.055.016-.082.02-.234.05-.467.083-.709.108a.415.415 0 01-.1.017 9.33 9.33 0 01-.841.041c-.284 0-.567-.016-.85-.041-.034-.009-.067-.017-.1-.017a6.595 6.595 0 01-.709-.108c-.066-.009-.125-.025-.183-.042-.2-.05-.4-.1-.6-.159a1.883 1.883 0 01-.2-.066 7.275 7.275 0 01-.55-.2c-.067-.025-.133-.058-.2-.092a5.09 5.09 0 01-.525-.25c-.03-.016-.06-.035-.092-.054a5.045 5.045 0 00-.091-.054 4.936 4.936 0 01-.584-.359l-.083-.058-.11-.08a7.09 7.09 0 01-.365-.278c-.034-.023-.064-.05-.096-.077l-.046-.04c-.035-.031-.072-.061-.11-.092a1.766 1.766 0 01-.156-.133l.007-.01v-.006a8.312 8.312 0 01-2.69-6.117C.666 4.013 4.406.27 9 .27zM4.883 12.747c2.275-1.517 5.975-1.517 8.233 0 .367.241.675.524.917.833a7.066 7.066 0 002.05-4.975A7.088 7.088 0 009 1.52a7.088 7.088 0 00-7.084 7.084c0 1.933.784 3.691 2.05 4.975.25-.309.55-.592.917-.834zm.992-5.242a3.126 3.126 0 016.25 0 3.12 3.12 0 01-3.017 3.117H8.958c-1.758-.05-3.083-1.425-3.083-3.117z"
      fill="#737373"
    />
  </svg>
);

export default ProfileIcon;
