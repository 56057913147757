import {memo} from 'react';
import {Flex, Form} from 'antd';

import {Input, Select, Text} from '~components';
import {templateStatusOptions} from '~variables';

import {nameRules, statusRules, typeRules} from '../../utils';
import SelectTemplateType from './SelectTemplateType';

import './styles.scss';

const CheckListInfo = () => {
  return (
    <div className="fields">
      <Text className="fields__title">Thông tin checklist</Text>
      <div className="fields__content">
        <Flex gap={16}>
          <Form.Item name="name" rules={nameRules}>
            <Input label="Tên checklist" placeholder="Nhập tên checklist" required />
          </Form.Item>
          <Form.Item name="type" rules={typeRules}>
            <SelectTemplateType />
          </Form.Item>
          <Form.Item name="status" rules={statusRules}>
            <Select
              label="Trạng thái"
              placeholder="---Chọn trạng thái---"
              required
              options={templateStatusOptions}
            />
          </Form.Item>
        </Flex>
        <Form.Item name="description" noStyle>
          <Input label="Mô tả checklist" placeholder="Mô tả checklist" />
        </Form.Item>
      </div>
    </div>
  );
};

export default memo(CheckListInfo);
