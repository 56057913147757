import { SVGAttributes } from 'react';

const DeleteIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x={0.5} y={0.5} width={27} height={27} rx={3.5} fill="#FAFAFA" stroke="#EEE" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.345 9.336l-.125.742c-.547.03-1.093.072-1.637.127l-1.19.117a.44.44 0 00.093.875l1.19-.117c3.051-.309 6.131-.192 9.217.117h.047c.221 0 .414-.17.431-.397a.438.438 0 00-.39-.478c-.94-.092-1.88-.168-2.818-.22V10.1l-.128-.758-.001-.004C15.947 8.802 15.816 8 14.454 8h-1.529c-1.358 0-1.487.775-1.58 1.335zm3.832.152l.097.571a44.985 44.985 0 00-3.16-.018l.1-.56v-.005c.087-.514.101-.595.717-.595h1.529c.618 0 .635.099.717.607zm-3.36 11.053h3.745c2.036 0 2.118-1.125 2.182-2.035l.379-5.875a.438.438 0 00-.408-.466.446.446 0 00-.467.408l-.38 5.874c-.058.887-.08 1.22-1.306 1.22h-3.745c-1.218 0-1.242-.333-1.306-1.218v-.002l-.38-5.874a.442.442 0 00-.466-.408.442.442 0 00-.409.466l.38 5.875c.064.91.145 2.035 2.181 2.035zm2.84-3.208h-1.942a.44.44 0 01-.437-.437.44.44 0 01.437-.438h1.943a.44.44 0 01.437.438.44.44 0 01-.437.437zM12.232 15h2.917a.44.44 0 00.437-.438.44.44 0 00-.437-.437H12.23a.44.44 0 00-.437.438c0 .239.198.437.437.437z"
      fill="#333"
    />
  </svg>
);

export default DeleteIcon;
