import {Form} from 'antd';
import {DefaultOptionType} from 'antd/es/select';

import {Select} from '~components';
import {useGetDataSelect} from '~hooks';
import {TaskGroup} from '~models';
import {getTaskGroupsAPI} from '~services';
import {convertToOption} from '~utils';

import {AddTaskForm} from '../utils';

const SelectTaskGroup = () => {
  const {
    data: taskGroups,
    dataOptions: taskGroupOptions,
    handleSearchChange,
    handleEndReached,
  } = useGetDataSelect<TaskGroup>(getTaskGroupsAPI);

  const formInstance = Form.useFormInstance<AddTaskForm>();
  const selectedTaskGroups = Form.useWatch<TaskGroup[]>('taskGroups', formInstance) ?? [];

  const {setFieldValue, resetFields} = formInstance;
  const selectedTaskGroupOptions = selectedTaskGroups.map(convertToOption);

  const handleSelect = ({value}: DefaultOptionType) => {
    const selected = taskGroups.find(tg => tg.id === value);
    if (selected) {
      setFieldValue('taskGroups', selectedTaskGroups.concat(selected));
    }
  };

  const handleDeselect = ({value}: DefaultOptionType) => {
    const remainSelected = selectedTaskGroups.filter(tg => tg.id !== value);
    setFieldValue('taskGroups', remainSelected);
  };

  return (
    <Select
      label="Nhóm lỗi tham chiếu"
      placeholder="Nhập tên lỗi tham chiếu"
      mode="multiple"
      showSearch
      allowClear
      labelInValue
      filterOption={false}
      value={selectedTaskGroupOptions}
      options={taskGroupOptions}
      onSearch={handleSearchChange}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      onEndReached={handleEndReached}
      onClear={() => resetFields(['taskGroup'])}
    />
  );
};

export default SelectTaskGroup;
