import {useEffect, useRef} from 'react';

import {getAuthUserThunk, setAuthTokenAction} from '~reducers';
import {useAppDispatch, useAppSelector} from '~store';
import {Cookies, goToForlifeHome} from '~utils';

export const useInitApp = () => {
  const {token: authToken, user} = useAppSelector(state => state.authReducer);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = Cookies.get('token');
    const refreshToken = Cookies.get('refresh_token');
    if (token && refreshToken) {
      dispatch(setAuthTokenAction({token, refreshToken}));
    }
  }, [dispatch]);

  useEffect(() => {
    if (authToken) dispatch(getAuthUserThunk());
  }, [dispatch, authToken]);

  useEffect(() => {
    if (authToken && user) clearTimeout(timeoutRef.current);
    else timeoutRef.current = setTimeout(goToForlifeHome, 2500);
    return () => clearTimeout(timeoutRef.current);
  }, [authToken, user]);
};
