import {IconProps} from '~models';

const GroupPermissionIcon = ({fill = '#fff', size = 20, ...props}: IconProps) => (
  <svg
    width={size}
    height={(size * 20) / 22}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 21.75c-.67 0-1.35-.17-1.95-.52L2.11 17.8C.91 17.1.16 15.81.16 14.42V7.58c0-1.39.75-2.68 1.95-3.38L8.05.77c1.2-.7 2.69-.7 3.9 0l5.94 3.43c1.2.7 1.95 1.99 1.95 3.38v6.84c0 1.39-.75 2.68-1.95 3.38l-5.94 3.43c-.6.35-1.28.52-1.95.52zm0-20c-.41 0-.83.11-1.2.32L2.86 5.5c-.74.43-1.2 1.22-1.2 2.08v6.84c0 .85.46 1.65 1.2 2.08l5.94 3.43c.74.43 1.66.43 2.4 0l5.94-3.43c.74-.43 1.2-1.22 1.2-2.08V7.58c0-.85-.46-1.65-1.2-2.08L11.2 2.07c-.37-.21-.79-.32-1.2-.32zm0 9c-1.7 0-3.08-1.38-3.08-3.08 0-1.7 1.38-3.08 3.08-3.08 1.7 0 3.08 1.38 3.08 3.08 0 1.7-1.38 3.08-3.08 3.08zm0-4.66c-.87 0-1.58.71-1.58 1.58 0 .87.71 1.58 1.58 1.58.87 0 1.58-.71 1.58-1.58 0-.87-.71-1.58-1.58-1.58zm3.25 9.57c0 .41.34.75.75.75s.75-.34.75-.75c0-2.21-2.13-4.01-4.75-4.01s-4.75 1.8-4.75 4.01c0 .41.34.75.75.75s.75-.34.75-.75c0-1.38 1.46-2.51 3.25-2.51s3.25 1.13 3.25 2.51z"
      fill={fill}
    />
  </svg>
);

export default GroupPermissionIcon;
