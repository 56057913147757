import {Menu} from 'antd';

import {useAccessedMenuItems, useMenu} from '~hooks';
import {navigate} from '~routes';
import {useSiderContext} from '~contexts';

const SiderMenu = () => {
  const {collapsed} = useSiderContext();
  const {openKeys, setOpenKeys, selectedKeys} = useMenu({collapsed});
  const {accessedMenuItems} = useAccessedMenuItems();

  return (
    <Menu
      mode="inline"
      selectedKeys={selectedKeys}
      onSelect={({key}) => navigate(key)}
      openKeys={openKeys}
      onOpenChange={keys => setOpenKeys(keys)}
      items={accessedMenuItems}
    />
  );
};

export default SiderMenu;
