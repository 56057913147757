import {BaseSyntheticEvent} from 'react';
import {Select as AntdSelect, Empty, SelectProps} from 'antd';

import Text from '../Text';
import './styles.scss';
import {classNames} from '~utils';

interface Props extends SelectProps {
  className?: string;
  label?: string;
  required?: boolean;
  onEndReached?: () => void;
  onEndReachedThreshold?: number;
  horizontal?: boolean;
}

const Select = ({
  className,
  label,
  required,
  onEndReached,
  onEndReachedThreshold = 4,
  horizontal,
  ...props
}: Props) => {
  const handlePopupScroll = (event: BaseSyntheticEvent) => {
    const {scrollTop, offsetHeight, scrollHeight} = event.target;
    if (scrollTop + offsetHeight >= scrollHeight - onEndReachedThreshold) onEndReached?.();
  };

  return (
    <div
      className={classNames(`select ${className}`, {
        'select--horizontal': horizontal,
      })}>
      {label && (
        <div className="select__label">
          <Text className="select__label-text">{label}</Text>
          {required && <Text className="input__label-required">*</Text>}
        </div>
      )}
      <AntdSelect
        className="select__main"
        notFoundContent={
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Danh sách trống" />
        }
        onPopupScroll={handlePopupScroll}
        {...props}
      />
    </div>
  );
};

export default Select;
