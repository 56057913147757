import {User} from '~models';

import {axiosClient} from './axios-client';
import {ListResponse, ObjectResponse} from './service-models';

interface GetUsersAPIParams {
  search?: string;
  page?: number;
  per_page?: number;
}

export const getUserProfileAPI = () => {
  return axiosClient.get<ObjectResponse<User>>('/users/me');
};

export const getAllUserAPI = (params: GetUsersAPIParams) => {
  return axiosClient.get<ListResponse<User>>('/users', {params});
};
