import {Rule} from 'antd/es/form';

import {Task, TaskGroup} from '~models';

export interface AddTaskForm {
  name: string;
  active: boolean;
  parentTask?: Task;
  taskGroups: TaskGroup[];
}

export const initialValues: AddTaskForm = {
  name: '',
  active: true,
  taskGroups: [],
};

export const nameRules: Rule[] = [
  {
    required: true,
    message: 'Không được để trống',
  },
];
