import {useCallback, useMemo, useState} from 'react';

import {Base} from '~models';

export const useTableRowSelection = <D extends Base>() => {
  const [selectedRows, setSelectedRows] = useState<D[]>([]);

  const selectRow = useCallback((record: D, selected: boolean) => {
    setSelectedRows(preSelectedRows =>
      selected ? preSelectedRows.concat(record) : preSelectedRows.filter(t => t.id !== record.id),
    );
  }, []);

  const selectAllRow = useCallback((selected: boolean, allRows: D[]) => {
    setSelectedRows(selected ? allRows : []);
  }, []);

  const selectedRowKeys = useMemo(() => selectedRows.map(t => t.id), [selectedRows]);

  return {
    selectedRowKeys,
    selectedRows,
    setSelectedRows,
    selectRow,
    selectAllRow,
  };
};
