import {combineReducers} from '@reduxjs/toolkit';

import {taskReducer} from '../reducers/task';
import {taskGroupReducer} from '../reducers/task-group';
import {templateReducer} from '../reducers/template';
import {authReducer} from '../reducers/auth';
import {groupPermissionReducer} from '../reducers/group-permission';
import {employeeReducer} from '../reducers/employee';

const rootReducer = combineReducers({
  taskReducer,
  taskGroupReducer,
  templateReducer,
  authReducer,
  groupPermissionReducer,
  employeeReducer,
});

export default rootReducer;
