import {RouterProvider, createBrowserRouter} from 'react-router-dom';

import {AllPermission} from '~variables';
import {
  LoginPage,
  LayoutPage,
  TaskPage,
  TemplatePage,
  TaskGroupPage,
  GroupPermissionPage,
  AddTemplatePage,
  ProfilePage,
  EmployeePage,
  PermissionsPage,
  NotFoundPage,
} from '~pages';

import WrapperRoute from './WrapperRoute';

const router = createBrowserRouter([
  {
    path: '/login',
    element: (
      <WrapperRoute
        auth={false}
        element={<LoginPage />}
        title="Login"
        permission={AllPermission.All__Allowed}
      />
    ),
  },
  {
    path: '/',
    element: (
      <WrapperRoute element={<LayoutPage />} title="" permission={AllPermission.All__Allowed} />
    ),
    children: [
      // {
      //   index: true,
      //   element: <WrapperRoute element={<HomePage />} title="Home" />,
      // },
      {
        index: true,
        // path: 'task',
        element: (
          <WrapperRoute
            element={<TaskPage />}
            title="Task"
            permission={AllPermission.Tasks__View}
          />
        ),
      },
      {
        path: 'template',
        children: [
          {
            index: true,
            element: (
              <WrapperRoute
                element={<TemplatePage />}
                title="Template"
                permission={AllPermission.Templates__View}
              />
            ),
          },
          {
            path: 'add',
            element: (
              <WrapperRoute
                element={<AddTemplatePage />}
                title="Add Template"
                permission={AllPermission.Templates__View}
              />
            ),
          },
          {
            path: 'add/:templateId',
            element: (
              <WrapperRoute
                element={<AddTemplatePage />}
                title="Edit Template"
                permission={AllPermission.Templates__View}
              />
            ),
          },
        ],
      },
      {
        path: 'task-group',
        element: (
          <WrapperRoute
            element={<TaskGroupPage />}
            title="Task Group"
            permission={AllPermission.Task_groups__View}
          />
        ),
      },
      {
        path: 'group-permission',
        children: [
          {
            index: true,
            element: (
              <WrapperRoute
                element={<GroupPermissionPage />}
                title="Group Permission"
                permission={AllPermission.Group_permissions__View}
              />
            ),
          },
          {
            path: ':groupPermissionId/employee',
            element: (
              <WrapperRoute
                element={<EmployeePage />}
                title="Employee"
                permission={AllPermission.Group_permissions__View}
              />
            ),
          },
          {
            path: ':groupPermissionId/permission',
            element: (
              <WrapperRoute
                element={<PermissionsPage />}
                title="Permission"
                permission={AllPermission.Group_permissions__View}
              />
            ),
          },
        ],
      },
      {
        path: '/profile',
        element: (
          <WrapperRoute
            element={<ProfilePage />}
            title="Profile"
            permission={AllPermission.All__Allowed}
          />
        ),
      },
      {
        path: '*',
        element: (
          <WrapperRoute
            element={<NotFoundPage />}
            title="Not Found"
            permission={AllPermission.All__Allowed}
          />
        ),
      },
    ],
  },
]);

const RenderRoutes = () => {
  return <RouterProvider router={router} />;
};

export const navigate = router.navigate;

export default RenderRoutes;
