import {DataType} from '~models';

const getTypeOf = (value?: unknown) => {
  return Object.prototype.toString.call(value).slice(8, -1) as DataType;
};

export const isUndefined = (value: unknown) => getTypeOf(value) === 'Undefined';
export const isNull = (value: unknown) => getTypeOf(value) === 'Null';
export const isString = (value: unknown) => getTypeOf(value) === 'String';
export const isNumber = (value: unknown) => getTypeOf(value) === 'Number';
export const isBoolean = (value: unknown) => getTypeOf(value) === 'Boolean';

export const isNullOrUndefined = (value: unknown): value is unknown => {
  return isNull(value) || isUndefined(value);
};
