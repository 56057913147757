import {useCallback, useEffect, useState} from 'react';

import {CommonObject, ListPagination, Pagination} from '~models';
import {useAppDispatch} from '~store';

import {useMemoSearchParams} from './useMemoSearchParams';

export const useGetData = <D, P extends CommonObject<string>>(actionThunk: any, payload?: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<D[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    current_page: 1,
    per_page: 10,
    from: 1,
  });

  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams, locationSearch] = useMemoSearchParams<P>();

  const getData = useCallback(() => {
    dispatch(
      actionThunk({
        setIsLoading,
        ...(payload && {payload}),
        callback: (data: ListPagination<D>) => {
          setData(data.data);
          setPagination(data.pagination);
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionThunk, JSON.stringify(payload), dispatch]);

  useEffect(() => {
    getData();
  }, [getData, locationSearch]);

  return {
    isLoading,
    data,
    getData,
    pagination,
    dispatch,
    searchParams,
    setSearchParams,
  };
};
