import {ChangeEvent} from 'react';

import {Input} from '~components';
import {useDebounceFunction} from '~hooks';
import {TaskGroupFilterParams} from '~models';

import './styles.scss';

interface Props {
  searchParams: TaskGroupFilterParams;
  setSearchParams: (values: TaskGroupFilterParams) => void;
}

const TaskGroupFilter = ({searchParams, setSearchParams}: Props) => {
  const handleSearchChange = useDebounceFunction<ChangeEvent<HTMLInputElement>>(event => {
    setSearchParams({search: event.target.value});
  });

  return (
    <div className="task-group-filter">
      <Input
        className="field"
        placeholder="Nhập tên nhóm lỗi tham chiếu"
        label="Nhóm lỗi tham chiếu"
        horizontal
        allowClear
        defaultValue={searchParams.search}
        onChange={handleSearchChange}
      />
    </div>
  );
};

export default TaskGroupFilter;
