import {Flex, ModalProps, Radio, RadioChangeEvent} from 'antd';
import {useState} from 'react';

import {Modal} from '~components';
import {TemplateStatus} from '~models';
import {editTemplateStatusThunk} from '~reducers';
import {useAppDispatch} from '~store';
import {templateStatusOptions} from '~variables';
import {useTemplateContext} from '~contexts';

interface Props extends ModalProps {
  hideModal: () => void;
}

const ChangeStatusModal = ({hideModal, ...props}: Props) => {
  const [status, setStatus] = useState<TemplateStatus>(TemplateStatus.DRAFT);

  const dispatch = useAppDispatch();
  const {getTemplates, selectedTemplates, setSelectedTemplates} = useTemplateContext();

  const handleSelect = (event: RadioChangeEvent) => {
    setStatus(event.target.value);
  };

  const handleSave = () => {
    dispatch(
      editTemplateStatusThunk({
        payload: {
          status,
          templates: selectedTemplates,
        },
        callback: () => {
          hideModal();
          getTemplates();
          setSelectedTemplates([]);
        },
      }),
    );
  };

  return (
    <Modal
      width={300}
      title="Đổi trạng thái"
      cancelText="Huỷ"
      okText="Lưu"
      onOk={handleSave}
      onCancel={hideModal}
      afterClose={() => setStatus(TemplateStatus.DRAFT)}
      {...props}>
      <Radio.Group onChange={handleSelect} value={status}>
        <Flex vertical gap={20}>
          {templateStatusOptions.map(o => (
            <Radio key={o.value} value={o.value}>
              {o.label}
            </Radio>
          ))}
        </Flex>
      </Radio.Group>
    </Modal>
  );
};

export default ChangeStatusModal;
