import React, { SVGAttributes } from 'react';

const EditIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x={0.5} y={0.5} width={27} height={27} rx={3.5} fill="#FAFAFA" stroke="#EEE" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.92 18.555c-.357 0-.69-.123-.928-.35-.304-.286-.45-.718-.397-1.184l.216-1.89c.04-.356.256-.829.507-1.085L13.31 9.82l.008-.007.79-.837c1.195-1.265 2.444-1.3 3.71-.105 1.265 1.196 1.3 2.444.104 3.71l-4.789 5.07c-.245.262-.7.507-1.055.565l-1.879.321-.103.008c-.06.005-.117.01-.177.01zm.034-3.915l3.452-3.658a4.001 4.001 0 002.54 2.406l-3.454 3.656a1.304 1.304 0 01-.566.303l-1.879.321c-.192.03-.35-.006-.455-.105-.105-.1-.151-.257-.128-.45l.216-1.89c.023-.168.157-.46.274-.583zm7.327-2.665l-.645.682a3.134 3.134 0 01-2.533-2.413l.64-.678c.397-.42.788-.7 1.237-.7.362 0 .764.186 1.23.635 1.057.992.794 1.704.07 2.474zm-8.843 8.464h10.5a.44.44 0 00.437-.437.44.44 0 00-.438-.438h-10.5a.44.44 0 00-.437.438c0 .239.198.437.438.437z"
      fill="#292D32"
    />
  </svg>
);

export default EditIcon;
