import queryString from 'query-string';
import {useCallback, useMemo} from 'react';

import {CommonObject} from '~models';
import {navigate} from '~routes';
import {getSearchParamsWindow} from '~utils';

import {useMemoLocation} from './useMemoLocation';

export const useMemoSearchParams = <T extends CommonObject<string>>() => {
  const location = useMemoLocation();

  const searchParams: T = useMemo(() => {
    return queryString.parse(location.search, {
      parseBooleans: true,
      parseNumbers: true,
    });
  }, [location.search]) as T;

  const setSearchParams = useCallback((newParams: T) => {
    const currentSearchParams = getSearchParamsWindow<T>();

    navigate(
      queryString.stringifyUrl(
        {
          url: '',
          query: {
            ...currentSearchParams,
            ...newParams,
            ...(!newParams.current_page &&
              currentSearchParams.current_page !== 1 && {
                current_page: 1,
              }),
          },
        },
        {
          skipEmptyString: true,
          skipNull: true,
        },
      ),
      {
        replace: true,
      },
    );
  }, []);

  return [searchParams, setSearchParams, location.search] as const;
};
