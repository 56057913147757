import { SVGAttributes } from 'react';

const PreviousIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={6}
    height={10}
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M4.322.547l-2.14 2.14L.87 3.994a1.42 1.42 0 000 2.007l3.453 3.453c.454.453 1.227.127 1.227-.507V1.054c0-.64-.773-.96-1.227-.507z"
      fill="#737373"
    />
  </svg>
);

export default PreviousIcon;
