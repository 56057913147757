import {useCallback, useEffect, useMemo, useState} from 'react';

import {Base, Pagination} from '~models';
import {API} from '~services';
import {convertToOption} from '~utils';

import {useDebounceFunction} from './useDebounceFunction';

export enum GetDataSelectMode {
  MORE = 'MORE',
  PAGE = 'PAGE',
}

export const useGetDataSelect = <D extends Base>(
  getDataAPI: API<D>,
  loadMode = GetDataSelectMode.MORE,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<D[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pagination, setPagination] = useState<Pagination>({
    current_page: 1,
    from: 1,
    per_page: 10,
  });

  const dataOptions = useMemo(() => data.map(convertToOption), [data]);

  const handleEndReached = useCallback(() => {
    if (pagination.current_page < Number(pagination.last_page)) {
      setPagination({
        ...pagination,
        current_page: pagination.current_page + 1,
      });
    }
  }, [pagination]);

  const handleSearchChange = useDebounceFunction<string>(value => {
    setSearchTerm(value);
    setPagination({...pagination, current_page: 1});
  });

  useEffect(() => {
    setIsLoading(true);
    getDataAPI({
      search: searchTerm,
      page: pagination.current_page,
      per_page: pagination.per_page,
    })
      .then(response => {
        const data = response.data.data;
        if (
          loadMode === GetDataSelectMode.PAGE ||
          (loadMode === GetDataSelectMode.MORE && pagination.current_page === 1)
        ) {
          setData(data.data);
          setPagination(data.pagination);
        } else {
          setData(preData => [...preData, ...data.data]);
        }
      })
      .finally(() => setIsLoading(false));
  }, [loadMode, searchTerm, pagination.current_page, pagination.per_page, getDataAPI]);

  return {
    isLoading,
    data,
    dataOptions,
    searchTerm,
    pagination,
    setPagination,
    setSearchTerm,
    handleEndReached,
    handleSearchChange,
  };
};
