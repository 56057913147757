import {useState} from 'react';

import {Select} from '~components';
import {TemplateAction} from '~models';
import {templateActionOptions} from '~variables';
import {classNames} from '~utils';

import ChangeStatusModal from './ChangeStatusModal';
import {useTemplateContext} from '~contexts';

const FilterAction = () => {
  const [action, setAction] = useState<TemplateAction>();

  const {selectedTemplates} = useTemplateContext();

  return (
    <div className="filter-action">
      <Select
        className={classNames('filter-action__input', {
          'filter-action__input--disabled': selectedTemplates.length,
        })}
        placement="bottomLeft"
        value="Hành động"
        popupMatchSelectWidth={false}
        options={templateActionOptions}
        onSelect={setAction}
        disabled={!selectedTemplates.length}
      />

      <ChangeStatusModal
        open={action === TemplateAction.CHANGE_STATUS}
        hideModal={() => setAction(undefined)}
      />
    </div>
  );
};

export default FilterAction;
