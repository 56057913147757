import React, { SVGAttributes } from 'react';

const DropdownIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={12}
    height={7}
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6 6.2c-.467 0-.934-.18-1.287-.534L.366 1.32a.503.503 0 010-.706.503.503 0 01.707 0L5.42 4.959c.32.32.84.32 1.16 0L10.926.613a.503.503 0 01.707 0 .503.503 0 010 .706L7.286 5.666C6.933 6.02 6.466 6.2 6 6.2z"
      fill="#333"
    />
  </svg>
);

export default DropdownIcon;
