import RenderRoutes from '~routes';
import {useInitApp} from '~hooks';
import {useAppSelector} from '~store';
import {Loading} from '~components';

function App() {
  const user = useAppSelector(state => state.authReducer.user);

  useInitApp();

  return user ? <RenderRoutes /> : <Loading />;
}

export default App;
