import {useMemo} from 'react';

import {useAppSelector} from '~store';
import {AllPermission} from '~variables';

export const usePermission = () => {
  const {user} = useAppSelector(state => state.authReducer);

  return useMemo(() => {
    const isAdmin = user?.is_admin;
    const permissions = user?.permissions ?? [];

    return {
      user,
      allowedPermissions: isAdmin
        ? Object.values(AllPermission)
        : permissions.map(p => `${p.module}__${p.action}`),
    };
  }, [user]);
};
