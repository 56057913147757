import { createSlice } from '@reduxjs/toolkit';

interface TaskGroupState {
  // isLoading: boolean;
  // taskGroups: TaskGroup[];
  // pagination: Pagination;
  // filter: Partial<TaskGroupFilterParams>;
}

const initialState: TaskGroupState = {
  // isLoading: false,
  // taskGroups: [],
  // filter: {},
  // pagination: {
  //   current_page: 1,
  //   per_page: 10,
  //   from: 1,
  // },
};

const taskGroupSlice = createSlice({
  name: 'TASK_GROUP_SLICE',
  initialState,
  reducers: {
    // setTaskGroupIsLoadingAction: (state, { payload }: PayloadAction<boolean>) => {
    //   state.isLoading = payload;
    // },
    // setTaskGroupsAction: (state, { payload }: PayloadAction<ListPagination<TaskGroup>>) => {
    //   state.taskGroups = payload.data;
    //   state.pagination = payload.pagination;
    // },
    // setTaskGroupFilterAction: (state, { payload }: PayloadAction<TaskGroupFilterParams>) => {
    //   state.filter.search = payload.search;
    //   if (state.pagination.current_page !== 1) state.pagination.current_page = 1;
    //   // if (state.taskGroups.length) state.taskGroups = [];
    // },
    // setTaskGroupPaginationAction: (state, { payload }: PayloadAction<Partial<Pagination>>) => {
    //   state.pagination = {
    //     ...state.pagination,
    //     ...payload,
    //     ...(payload.per_page && { current_page: 1 }),
    //   };
    //   // if (state.taskGroups.length) state.taskGroups = [];
    // },
    // resetTaskGroupFilterAction: state => {
    //   state.filter = initialState.filter;
    //   state.pagination = initialState.pagination;
    //   state.taskGroups = initialState.taskGroups;
    // },
  },
});

export const taskGroupReducer = taskGroupSlice.reducer;
export * from './async-actions';
// export const {
//   setTaskGroupIsLoadingAction,
//   setTaskGroupsAction,
//   setTaskGroupFilterAction,
//   setTaskGroupPaginationAction,
//   resetTaskGroupFilterAction,
// } = taskGroupSlice.actions;
