import {IconProps} from '~models';

const ChecklistIcon = ({fill = '#fff', size = 22, ...props}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 21.75h6c5.43 0 7.75-2.32 7.75-7.75V8c0-5.43-2.32-7.75-7.75-7.75H8C2.57.25.25 2.57.25 8v6c0 5.43 2.32 7.75 7.75 7.75zM1.75 8c0-4.61 1.64-6.25 6.25-6.25h6c4.61 0 6.25 1.64 6.25 6.25v6c0 4.61-1.64 6.25-6.25 6.25H8c-4.61 0-6.25-1.64-6.25-6.25V8zm4.37 1.38c-.19 0-.38-.07-.53-.22l-.75-.75a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l.22.22 1.72-1.72c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L6.65 9.16a.75.75 0 01-.53.22zm10.5-.76h-5.25c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5.25a.749.749 0 110 1.5zm-5.25 7h5.25a.749.749 0 100-1.5h-5.25c-.41 0-.75.34-.75.75s.34.75.75.75zm-5.25.76c-.19 0-.38-.07-.53-.22l-.75-.75a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l.22.22 1.72-1.72c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-2.25 2.25a.75.75 0 01-.53.22z"
        fill={fill}
      />
    </svg>
  );
};

export default ChecklistIcon;
