import {Task, Template, TemplateCategory, TemplateStatus, TemplateType} from '~models';

import {axiosClient} from './axios-client';
import {ListResponse, ObjectResponse, SuccessResponse} from './service-models';

interface AddTemplateAPIBody {
  name: string;
  description?: string;
  type: string;
  status: TemplateStatus;
  tasks: Task[];
}

interface GetTemplatesAPIParams {
  search?: string;
  category?: TemplateCategory;
  created_by?: string;
  statuses?: TemplateStatus[];
  deletable?: number;
  type?: string;
  page?: number;
  per_page?: number;
  department?: string;
}

interface EditTemplateAPIPayload {
  id: string;
  name?: string;
  description?: string;
  status?: TemplateStatus;
  tasks?: Task[];
}

interface EditTemplateStatusAPIBody {
  status: TemplateStatus;
  templates: string[];
}

export const addTemplateAPI = (body: AddTemplateAPIBody) => {
  return axiosClient.post<SuccessResponse>('/templates', body);
};

export const getTemplateTypeAPI = () => {
  return axiosClient.get<ListResponse<TemplateType>>('/templates/types');
};

export const getTemplatesAPI = (params: GetTemplatesAPIParams) => {
  return axiosClient.get<ListResponse<Template>>('/templates', {params});
};

export const getTemplateById = (id: string) => {
  return axiosClient.get<ObjectResponse<Template>>(`/templates/${id}`);
};

export const editTemplateAPI = ({id, ...body}: EditTemplateAPIPayload) => {
  return axiosClient.patch<SuccessResponse>(`templates/${id}`, body);
};

export const deleteTemplateAPI = (id: string) => {
  return axiosClient.delete<SuccessResponse>(`/templates/${id}`);
};

export const editTemplateStatusAPI = (body: EditTemplateStatusAPIBody) => {
  return axiosClient.patch<SuccessResponse>('/templates-batch', body);
};
