import {createAsyncThunk} from '@reduxjs/toolkit';
import {notification} from 'antd';
import {HttpStatusCode} from 'axios';

import {addEmployeesAPI, deleteEmployeesAPI, getEmployeesAPI} from '~services';
import {getSearchParamsWindow} from '~utils';
import {EmployeeFilterParams} from '~models';

import {AddEmployeesPayload, DeleteEmployeesPayload, GetEmployeesPayload} from './action-models';

export const addEmployeesThunk = createAsyncThunk(
  'EMPLOYEE_SLICE/ADD_EMPLOYEES_THUNK',
  async ({payload, callback}: AddEmployeesPayload) => {
    try {
      const response = await addEmployeesAPI({
        groupPermissionId: payload.groupPermissionId,
        users: payload.employees.map(em => em.id),
      });

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Thêm mới thành công',
          description: 'Thêm mới thành viên thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Thêm mới thất bại',
        description: error.response.data.message,
      });
    }
  },
);

export const getEmployeesThunk = createAsyncThunk(
  'EMPLOYEE_SLICE/GET_EMPLOYEES_THUNK',
  async ({payload, setIsLoading, callback}: GetEmployeesPayload) => {
    try {
      setIsLoading(true);
      const searchParams = getSearchParamsWindow<EmployeeFilterParams>();

      const response = await getEmployeesAPI({
        groupPermissionId: payload.groupPermissionId,
        search: searchParams.search,
        page: searchParams.current_page,
        per_page: searchParams.per_page,
      });

      if (response.status === HttpStatusCode.Ok) {
        callback(response.data.data);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  },
);

export const deleteEmployeesThunk = createAsyncThunk(
  'EMPLOYEE_SLICE/DELETE_EMPLOYEES_THUNK',
  async ({payload, callback}: DeleteEmployeesPayload) => {
    try {
      const response = await deleteEmployeesAPI({
        groupPermissionId: payload.groupPermissionId,
        users: payload.employees.map(em => em.id),
      });

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Xoá thành công',
          description: 'Xoá thành viên thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Thêm mới thất bại',
        description: error.response.data.message,
      });
    }
  },
);
