import {useMemo} from 'react';
import {PlusCircleOutlined} from '@ant-design/icons';

import {useGetData, usePermission, useTableRowSelection} from '~hooks';
import {AllPermission} from '~variables';
import {Button, PageHeader, Table} from '~components';
import {Template, TemplateCategory, TemplateFilterParams} from '~models';
import {getTemplatesThunk} from '~reducers';
import {navigate} from '~routes';
import {TemplateContext} from '~contexts';

import {getTemplateColumns} from './utils';
import {TemplateFilter} from './components';
import './styles.scss';

const TemplatePage = () => {
  const {user, allowedPermissions} = usePermission();

  const {
    selectedRowKeys,
    selectedRows: selectedTemplates,
    setSelectedRows: setSelectedTemplates,
    selectRow,
    selectAllRow,
  } = useTableRowSelection<Template>();

  const {
    isLoading,
    pagination,
    searchParams,
    data: templates,
    getData: getTemplates,
    dispatch,
    setSearchParams,
  } = useGetData<Template, TemplateFilterParams>(getTemplatesThunk);

  const isPublicPage = useMemo(
    () => searchParams.category === TemplateCategory.PUBLIC,
    [searchParams.category],
  );

  const columns = useMemo(() => {
    return getTemplateColumns({
      user,
      allowedPermissions,
      isPublicPage,
      searchTerm: searchParams.search,
      templatesLength: templates.length,
      pagination,
      dispatch,
      getTemplates,
      setSearchParams,
    });
  }, [
    user,
    allowedPermissions,
    isPublicPage,
    searchParams.search,
    templates.length,
    pagination,
    dispatch,
    getTemplates,
    setSearchParams,
  ]);

  return (
    <TemplateContext.Provider
      value={{
        getTemplates,
        selectedTemplates,
        setSelectedTemplates,
      }}>
      <div className="template">
        <PageHeader
          title={`DANH SÁCH CHECKLIST ${isPublicPage ? 'ĐÃ BAN HÀNH' : 'CỦA TÔI'}`}
          actions={
            allowedPermissions.includes(AllPermission.Templates__Create) &&
            !isPublicPage && (
              <Button
                type="primary"
                size="large"
                icon={<PlusCircleOutlined />}
                onClick={() => navigate(`/template/add`)}>
                Thêm mới
              </Button>
            )
          }
        />

        <TemplateFilter
          isPublicPage={isPublicPage}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />

        <Table
          className="template__table"
          emptyText="Danh sách template trống"
          loading={isLoading}
          columns={columns}
          dataSource={templates}
          {...(!isPublicPage && {
            rowSelection: {
              selectedRowKeys,
              onSelect: selectRow,
              onSelectAll: selectAllRow,
            },
          })}
          pagination={{
            from: pagination.from,
            to: pagination.to,
            total: pagination.total,
            current: pagination.current_page,
            pageSize: pagination.per_page,
            onChange: current_page => setSearchParams({current_page}),
            onShowSizeChange: (_, per_page) => setSearchParams({per_page, current_page: 1}),
          }}
        />
      </div>
    </TemplateContext.Provider>
  );
};

export default TemplatePage;
