import {Flex, Form, ModalProps, Switch} from 'antd';
import {useEffect} from 'react';

import {useAppDispatch} from '~store';
import {Modal, Text, TextArea} from '~components';
import {ModalMode, Task} from '~models';
import {addTaskThunk, editTaskThunk} from '~reducers';
import {getTaskByIdAPI} from '~services';

import {AddTaskForm, initialValues, nameRules} from '../../utils';
import SelectTaskGroup from '../SelectTaskGroup';
import './styles.scss';

interface Props extends ModalProps {
  mode?: ModalMode;
  selectedTaskId: string;
  setSelectedTaskId: (value: string) => void;
  setModalMode: (mode?: ModalMode) => void;
  getTasks: () => void;
  expandInteractedRow: () => void;
}

const TaskModal = ({
  mode,
  selectedTaskId,
  setModalMode,
  setSelectedTaskId,
  getTasks,
  expandInteractedRow,
  ...props
}: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<AddTaskForm>();
  const parentTask = Form.useWatch('parentTask', form);

  const {submit, setFieldsValue, resetFields} = form;

  const hideModal = () => {
    setSelectedTaskId('');
    setModalMode(undefined);
  };

  const onAddSuccess = () => {
    if (selectedTaskId) {
      expandInteractedRow();
    }
    hideModal();
    getTasks();
  };

  const handleSubmit = (values: AddTaskForm) => {
    if (mode === ModalMode.EDIT) {
      dispatch(
        editTaskThunk({
          payload: {
            ...values,
            taskId: selectedTaskId,
          },
          callback: onAddSuccess,
        }),
      );
    } else {
      dispatch(
        addTaskThunk({
          payload: values,
          callback: onAddSuccess,
        }),
      );
    }
  };

  useEffect(() => {
    if (selectedTaskId) {
      getTaskByIdAPI(selectedTaskId).then(response => {
        const selectedTask: Task = response.data.data;
        let fieldsValue = null;

        if (mode === ModalMode.ADD_CHILD) {
          fieldsValue = {parentTask: selectedTask};
        } else {
          fieldsValue = {
            name: selectedTask.name,
            active: selectedTask.active,
            taskGroups: selectedTask.groups,
            ...(selectedTask.parent && {parentTask: selectedTask.parent}),
          };
        }

        setFieldsValue(fieldsValue);
      });
    }
  }, [mode, selectedTaskId, setFieldsValue]);

  return (
    <Modal
      width={700}
      className="task-modal"
      title={`${selectedTaskId ? 'Chỉnh sửa' : 'Tạo mới'} nội dung kiểm soát`}
      cancelText="Huỷ"
      okText={selectedTaskId ? 'Lưu' : 'Thêm mới'}
      onOk={submit}
      onCancel={hideModal}
      afterClose={resetFields}
      {...props}>
      <Form initialValues={initialValues} form={form} onFinish={handleSubmit}>
        <Form.Item name="parentTask" noStyle>
          {parentTask && (
            <Text className="parent-task">
              <Text>Nội dung cha: </Text>
              <Flex vertical className="parent-task__name">
                {parentTask?.name?.split('\n').map(l => (
                  <Text key={l} className="parent-task__name-line">
                    {l}
                  </Text>
                ))}
              </Flex>
            </Text>
          )}
        </Form.Item>

        <Form.Item name="name" rules={nameRules}>
          <TextArea
            label="Tên nội dung kiểm soát"
            placeholder="Nhập tên nội dung kiểm soát"
            numberOfLines={6}
            required
          />
        </Form.Item>

        <Form.Item name="taskGroups">
          <SelectTaskGroup />
        </Form.Item>

        <div className="active">
          <Text className="active__text">
            Hoạt động (Kích hoạt / Vô hiệu hóa) <span className="active__text--required">*</span>
          </Text>
          <Form.Item name="active" valuePropName="checked" noStyle>
            <Switch />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default TaskModal;
