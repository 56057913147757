import {useMemo} from 'react';

import {menuItems} from '~variables';

import {usePermission} from './usePermission';

export const useAccessedMenuItems = () => {
  const {allowedPermissions} = usePermission();

  return useMemo(() => {
    const accessedMenuItems = menuItems.filter(item =>
      allowedPermissions.includes(item.permission),
    );
    return {accessedMenuItems};
  }, [allowedPermissions]);
};
