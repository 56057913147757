import { SVGAttributes } from 'react';

const ArrowTopIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={14}
    height={20}
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.399 7.043c-.15.15-.22.34-.22.53s.07.39.22.53c.29.29.77.29 1.06 0l4.79-4.79v15.19c0 .41.34.75.75.75s.75-.34.75-.75V3.313l4.79 4.79c.29.29.77.29 1.06 0 .29-.29.29-.77 0-1.06L7.529.973a.754.754 0 00-1.06 0l-6.07 6.07z"
      fill="#EEE"
    />
  </svg>
);

export default ArrowTopIcon;
