import {IconProps} from '~models';

const TaskGroupIcon = ({fill = '#fff', size = 20, ...props}: IconProps) => (
  <svg
    width={size}
    height={(size * 20) / 22}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.75H8c-.851 0-2.356 0-2.685-1.919-2.362.277-3.565 1.325-3.565 5.17v6c0 3.7.73 5.25 5.25 5.25h6c4.52 0 5.25-1.55 5.25-5.25V9c0-3.833-1.196-4.886-3.543-5.167-.129.909-.644 1.916-2.707 1.916zm1.25-2.697C13.25 4 13.227 4.25 12 4.25H8c-.974 0-1.241 0-1.25-1.19a.78.78 0 000-.07c.001-1.24.263-1.24 1.25-1.24h4c.985 0 1.247 0 1.25 1.23a.725.725 0 000 .073zm1.459-.728C14.436.25 12.874.25 12 .25H8c-.875 0-2.44 0-2.71 2.083C1.718 2.721.25 4.7.25 9v6c0 4.17 1.13 6.75 6.75 6.75h6c5.62 0 6.75-2.58 6.75-6.76v-6c0-4.291-1.468-6.27-5.041-6.665zM11.47 15.69c.15.15.34.22.53.22s.38-.08.53-.22c.29-.29.29-.77 0-1.06l-1.45-1.45 1.41-1.41c.29-.29.29-.77 0-1.06a.754.754 0 00-1.06 0l-1.41 1.41-1.45-1.45a.754.754 0 00-1.06 0c-.29.29-.29.77 0 1.06l1.45 1.45-1.49 1.49c-.29.29-.29.77 0 1.06.15.15.34.22.53.22s.38-.07.53-.22l1.49-1.49 1.45 1.45z"
      fill={fill}
    />
  </svg>
);

export default TaskGroupIcon;
