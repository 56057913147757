import {ReactNode, useRef} from 'react';
import {Table as AntdTable, Empty, TableProps} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

import {useTableHeight} from '~hooks';
import {classNames} from '~utils';

import Pagination, {PaginationProps} from '../Pagination';
import './styles.scss';

interface Props<T> extends TableProps<T> {
  className?: string;
  emptyText: string;
  emptyImage?: ReactNode;
  pagination: PaginationProps;
  showScroll?: boolean;
}

const Table = <T extends object>({
  className,
  emptyImage = Empty.PRESENTED_IMAGE_SIMPLE,
  emptyText,
  pagination,
  loading,
  showScroll = true,
  ...props
}: Props<T>) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const tableHeight = useTableHeight(tableRef);

  return (
    <AntdTable
      ref={tableRef}
      className={`table ${className}`}
      rowKey="id"
      pagination={false}
      scroll={{
        x: 'fit-content', // 'max-content'
        ...(showScroll && {
          scrollToFirstRowOnChange: true,
          y: tableHeight,
        }),
      }}
      loading={{
        spinning: !!loading,
        indicator: <LoadingOutlined className="table__loading" spin />,
      }}
      locale={{
        emptyText: (
          <Empty
            className={classNames({
              'table__empty--hidden': loading,
            })}
            image={emptyImage}
            description={emptyText}
          />
        ),
      }}
      footer={() => <Pagination {...pagination} />}
      {...props}
    />
  );
};

export default Table;
