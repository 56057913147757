import {Layout} from 'antd';
import {Outlet} from 'react-router-dom';

import {Content, Header, Sider} from '~layouts';

import './styles.scss';

const LayoutPage = () => {
  return (
    <Layout className="layout">
      <Sider />
      <Layout className="layout-main">
        <Header />
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutPage;
