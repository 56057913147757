import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {User} from '~models';

import {} from './action-models';

interface AuthState {
  refreshToken?: string;
  token?: string;
  user?: User;
}

const initialState: AuthState = {};

const authSlice = createSlice({
  name: 'AUTH_SLICE',
  initialState,
  reducers: {
    setAuthTokenAction: (state, {payload}: PayloadAction<AuthState>) => {
      state.token = payload.token;
      state.refreshToken = payload.refreshToken ?? state.refreshToken;
    },
    setAuthUserAction: (state, {payload}: PayloadAction<User>) => {
      state.user = payload;
    },
    resetAuthAction: state => {
      state.token = initialState.token;
      state.user = initialState.user;
    },
  },
});

export const authReducer = authSlice.reducer;
export * from './async-actions';
export const {setAuthTokenAction, setAuthUserAction, resetAuthAction} = authSlice.actions;
