import {Tooltip} from 'antd';
import {useMemo, useRef, useState} from 'react';
import {PlusCircleOutlined} from '@ant-design/icons';

import {Button, PageHeader, TreeTable} from '~components';
import {useGetData, usePermission} from '~hooks';
import {ModalMode, Task, TaskFilterParams, TreeTableForwardRef} from '~models';
import {getTasksThunk} from '~reducers';
import {AllPermission} from '~variables';
import {getRootNodeTreeId} from '~utils';

import {TaskFilter, AddTaskModal} from './components';
import {getColumns} from './utils';
import './styles.scss';

const TaskPage = () => {
  const [modalMode, setModalMode] = useState<ModalMode>();
  const [selectedTaskId, setSelectedTaskId] = useState<string>('');

  const {user, allowedPermissions} = usePermission();
  const treeTableRef = useRef<TreeTableForwardRef>(null);

  const {
    isLoading,
    pagination,
    searchParams,
    data: tasks,
    getData: getTasks,
    dispatch,
    setSearchParams,
  } = useGetData<Task, TaskFilterParams>(getTasksThunk);

  const expandInteractedRow = () => {
    const rootNodeTreeId = getRootNodeTreeId(tasks, selectedTaskId) as Task;
    treeTableRef.current?.handleExpand(true, rootNodeTreeId);
  };

  const columns = useMemo(() => {
    return getColumns({
      user,
      allowedPermissions,
      searchTerm: searchParams.search,
      tasksLength: tasks.length,
      pagination,
      dispatch,
      getTasks,
      setModalMode,
      setSearchParams,
      setSelectedTaskId,
    });
  }, [
    user,
    allowedPermissions,
    searchParams.search,
    tasks.length,
    pagination,
    dispatch,
    getTasks,
    setSearchParams,
  ]);

  return (
    <div className="task">
      <PageHeader
        title="DANH SÁCH NỘI DUNG KIỂM SOÁT CỦA TÔI"
        actions={
          allowedPermissions.includes(AllPermission.Tasks__Create) && (
            <Tooltip title="Thêm nội dung cha" placement="bottomRight">
              <Button
                type="primary"
                size="large"
                icon={<PlusCircleOutlined />}
                onClick={() => setModalMode(ModalMode.ADD)}>
                Thêm mới
              </Button>
            </Tooltip>
          )
        }
      />

      <TaskFilter searchParams={searchParams} setSearchParams={setSearchParams} />

      <TreeTable
        ref={treeTableRef}
        className="task__table"
        emptyText="Danh sách trống"
        loading={isLoading}
        columns={columns}
        dataSource={tasks}
        pagination={{
          from: pagination.from,
          to: pagination.to,
          total: pagination.total,
          current: pagination.current_page,
          pageSize: pagination.per_page,
          onChange: current_page => setSearchParams({current_page}),
          onShowSizeChange: (_, per_page) => setSearchParams({per_page, current_page: 1}),
        }}
      />

      <AddTaskModal
        open={!!modalMode}
        mode={modalMode}
        selectedTaskId={selectedTaskId}
        setSelectedTaskId={setSelectedTaskId}
        setModalMode={setModalMode}
        getTasks={getTasks}
        expandInteractedRow={expandInteractedRow}
      />
    </div>
  );
};

export default TaskPage;
