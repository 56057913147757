import {IconProps} from '~models';

const TaskIcon = ({fill = '#fff', size = 22, ...props}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.28 19.164c.183.012.359.016.53.016 2.96 0 4.75-1.73 5.61-5.39l.98-4.18c.55-2.34.42-4.05-.4-5.38-.83-1.33-2.31-2.2-4.65-2.75l-1.67-.39C12.414.557 9.256.24 7.497 3.446c-.259.062-.549.147-.897.254l-1.58.51C.64 5.63-.63 8.11.79 12.51l1.28 3.95c1.05 3.25 2.69 4.79 5.26 4.79.9 0 1.9-.18 3.05-.54l1.58-.52c.936-.303 1.697-.638 2.32-1.026zM6.767 5.224c-.084.283-.163.584-.238.906l-.98 4.19c-1.08 4.6.43 7.04 5.04 8.13l1.062.253a3.491 3.491 0 00-.162.057l-1.58.52c-3.57 1.15-5.25.29-6.41-3.28l-1.28-3.95c-1.15-3.58-.3-5.26 3.27-6.41l1.278-.416zm1.949-.88c.71-1.427 1.762-2.094 3.403-2.094.64 0 1.38.1 2.21.3l1.67.39c1.94.45 3.12 1.11 3.72 2.08.6.97.66 2.32.21 4.25l-.98 4.18c-.76 3.27-2.11 4.46-4.83 4.19-.46-.04-.97-.12-1.51-.25l-1.68-.4c-1.52-.362-2.595-.845-3.28-1.539-1.009-1.032-1.16-2.528-.63-4.78L8 6.48c.19-.84.42-1.51.68-2.04a.797.797 0 00.037-.097zM16.49 9.51c-.052 0-.105-.007-.164-.016L16.3 9.49l-4.85-1.23a.75.75 0 01.37-1.45l4.85 1.23a.748.748 0 01-.18 1.47zm-3.094 3.364c.06.009.112.016.164.016.34 0 .64-.22.72-.56a.75.75 0 00-.54-.91l-2.91-.74a.75.75 0 00-.37 1.45l2.91.74.026.004z"
      fill={fill}
    />
  </svg>
);

export default TaskIcon;
