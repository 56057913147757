import {ColumnsType} from 'antd/es/table';
import Highlighter from 'react-highlight-words';
import {Flex, Tag, Tooltip} from 'antd';

import {CommonObject, ModalMode, Pagination, Task, TaskGroup, User} from '~models';
import {DeleteIcon, EditIcon, PlusSquareIcon} from '~assets';
import {AllPermission, statusLabel} from '~variables';
import {classNames, getSlug, showConfirmModal, splitOrderAndLines} from '~utils';
import {AppDispatch} from '~store';
import {deleteTaskThunk} from '~reducers';

interface GetColumns {
  user?: User;
  searchTerm?: string;
  pagination: Pagination;
  tasksLength: number;
  dispatch: AppDispatch;
  setModalMode: (mode: ModalMode) => void;
  setSelectedTaskId: (taskId: string) => void;
  setSearchParams: (values: CommonObject<string>) => void;
  getTasks: () => void;
  allowedPermissions: string[];
}

export const getColumns = ({
  user,
  allowedPermissions,
  searchTerm,
  pagination,
  tasksLength,
  dispatch,
  setModalMode,
  setSelectedTaskId,
  setSearchParams,
  getTasks,
}: GetColumns): ColumnsType<Task> => {
  const editTask = (task: Task) => {
    setModalMode(ModalMode.EDIT);
    setSelectedTaskId(task.id);
  };

  const addChild = (task: Task) => {
    setModalMode(ModalMode.ADD_CHILD);
    setSelectedTaskId(task.id);
  };

  const deleteTask = (task: Task) => {
    showConfirmModal({
      title: 'Xoá nội dung kiểm soát',
      content: 'Sau khi xác nhận xóa bạn sẽ không thể hoàn tác',
      onOk: () => {
        dispatch(
          deleteTaskThunk({
            id: task.id,
            callback: () => {
              if (pagination.current_page > 1 && tasksLength === 1) {
                setSearchParams({current_page: 1});
              } else getTasks();
            },
          }),
        );
      },
    });
  };

  const renderStatus = (active: boolean) => {
    return active ? (
      <Tag color="success">{statusLabel.ACTIVE}</Tag>
    ) : (
      <Tag color="error">{statusLabel.INACTIVE}</Tag>
    );
  };

  const renderActions = (templateNum: number, task: Task) => {
    const canAddChild =
      allowedPermissions.includes(AllPermission.Tasks__Create) &&
      task.created_by?.username === user?.username;

    const canEdit =
      allowedPermissions.includes(AllPermission.Tasks__Update) &&
      task.created_by?.username === user?.username;

    const canDelete =
      allowedPermissions.includes(AllPermission.Tasks__Delete) &&
      task.created_by?.username === user?.username &&
      templateNum <= 0;

    return (
      <div className="icons">
        {canAddChild && (
          <Tooltip title="Thêm nội dung con" placement="bottomRight">
            <PlusSquareIcon
              className={classNames('icons__add-child', {
                'icons__add-child--hidden': !task.active,
              })}
              onClick={() => addChild(task)}
            />
          </Tooltip>
        )}
        {canEdit && (
          <Tooltip title="Chỉnh sửa" placement="bottomRight">
            <EditIcon className="icons__edit" onClick={() => editTask(task)} />
          </Tooltip>
        )}
        {canDelete && (
          <Tooltip title="Xoá nội dung" placement="bottomRight">
            <DeleteIcon className="icons__delete" onClick={() => deleteTask(task)} />
          </Tooltip>
        )}
      </div>
    );
  };

  const renderName = (name: string) => {
    const {order, lines} = splitOrderAndLines(name);
    const mappedLines = lines.map(l => (
      <Highlighter
        key={l}
        searchWords={[String(searchTerm)]}
        textToHighlight={l}
        sanitize={getSlug}
      />
    ));
    return (
      <Flex className="task-name">
        <span>{order}</span>&nbsp;
        <Flex vertical>{mappedLines}</Flex>
      </Flex>
    );
  };

  const renderTaskGroups = (taskGroups: TaskGroup[]) => {
    return (
      <Flex wrap="wrap" gap={6}>
        {taskGroups?.map(tg => (
          <Tag key={tg.id}>{tg.name}</Tag>
        ))}
      </Flex>
    );
  };

  return [
    {
      title: 'Nội dung',
      dataIndex: 'name',
      key: 'name',
      width: 600,
      render: renderName,
    },
    {
      title: 'Nhóm lỗi tham chiếu',
      dataIndex: 'groups',
      key: 'groups',
      width: 400,
      render: renderTaskGroups,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active',
      key: 'active',
      width: 150,
      render: renderStatus,
    },
    {
      title: 'Thao tác',
      dataIndex: 'template_num',
      width: 150,
      render: renderActions,
    },
  ];
};
