import {LoadingOutlined} from '@ant-design/icons';
import {Table as AntdTable, Empty} from 'antd';
import type {TableProps} from 'antd/es/table';
import type {TableRowSelection} from 'antd/es/table/interface';
import {RenderExpandIcon} from 'rc-table/lib/interface';
import {
  MouseEvent,
  ReactElement,
  ReactNode,
  Ref,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import {MinusSquareOutlinedIcon, PlusSquareOutlinedIcon} from '~assets';
import {useTableHeight} from '~hooks';
import {TreeDataType, TreeTableForwardRef} from '~models';
import {classNames, getAllIdExpandedTree, markOrderTable} from '~utils';

import Pagination, {PaginationProps} from '../Pagination';
import './styles.scss';

interface Props<T> extends TableProps<T> {
  className?: string;
  dataSource?: T[];
  rowSelection?: TableRowSelection<T>;
  surplus?: number;
  perPage?: number;
  emptyText: string;
  emptyImage?: ReactNode;
  pagination: PaginationProps;
  showScroll?: boolean;
}

const TreeTable = forwardRef(
  <T extends TreeDataType>(
    {
      className,
      dataSource,
      emptyImage = Empty.PRESENTED_IMAGE_SIMPLE,
      emptyText,
      pagination,
      loading,
      showScroll = true,
      expandable,
      ...props
    }: Props<T>,
    ref: Ref<TreeTableForwardRef<T>>,
  ) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const tableRef = useRef<HTMLDivElement>(null);
    const tableHeight = useTableHeight(tableRef);

    const {tableKey, orderedDataSource} = useMemo(() => {
      return {
        tableKey: JSON.stringify(dataSource),
        orderedDataSource: markOrderTable<T[]>(
          dataSource ?? [],
          String(pagination.from),
          Number(pagination.pageSize),
        ),
      };
    }, [dataSource, pagination.from, pagination.pageSize]);

    const renderExpandIcon: RenderExpandIcon<T> = ({record, expanded, onExpand}) => {
      if (record.firstLevel && record.children.length) {
        const ExpandIcon = expanded ? MinusSquareOutlinedIcon : PlusSquareOutlinedIcon;
        return (
          <ExpandIcon
            onClick={(e: unknown) => onExpand(record, e as MouseEvent<HTMLElement>)}
            className="expand-icon"
          />
        );
      }
      return <span className="expand-icon expand-icon--hide" />;
    };

    const handleExpand = useCallback((expanded: boolean, record: T) => {
      const changedExpandedRowKeys = getAllIdExpandedTree([record]);
      setExpandedRowKeys(preExpandedRowKeys =>
        expanded
          ? preExpandedRowKeys.concat(changedExpandedRowKeys)
          : preExpandedRowKeys.filter(k => !changedExpandedRowKeys.includes(k)),
      );
    }, []);

    useImperativeHandle(ref, () => ({handleExpand}), [handleExpand]);

    return (
      <AntdTable
        ref={tableRef}
        key={tableKey}
        className={`tree-table ${className}`}
        rowKey="id"
        pagination={false}
        scroll={{
          ...(showScroll && {
            scrollToFirstRowOnChange: true,
            y: tableHeight,
          }),
        }}
        loading={{
          spinning: !!loading,
          indicator: <LoadingOutlined className="tree-table__loading" spin />,
        }}
        locale={{
          emptyText: (
            <Empty
              className={classNames({
                'tree-table__empty--hidden': loading,
              })}
              image={emptyImage}
              description={emptyText}
            />
          ),
        }}
        expandable={{
          expandedRowKeys,
          onExpand: handleExpand,
          expandIcon: renderExpandIcon,
          ...expandable,
        }}
        footer={() => <Pagination {...pagination} />}
        dataSource={orderedDataSource}
        {...props}
      />
    );
  },
);

TreeTable.displayName = 'TreeTable';

export default TreeTable as <T extends TreeDataType>(
  p: Props<T> & {ref?: Ref<TreeTableForwardRef<T>>},
) => ReactElement;
