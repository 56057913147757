import {createSlice} from '@reduxjs/toolkit';

import {} from '~models';

interface TemplateState {}

const initialState: TemplateState = {};

const templateSlice = createSlice({
  name: 'TEMPLATE_SLICE',
  initialState,
  reducers: {},
});

export const templateReducer = templateSlice.reducer;
export * from './action-models';
export * from './async-actions';
// export const {} = templateSlice.actions;
