import { SVGAttributes } from 'react';

const LogoutIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={20}
    height={16}
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 2.333H1c-.41 0-.75-.453-.75-1 0-.547.34-1 .75-1h18c.41 0 .75.453.75 1 0 .547-.34 1-.75 1zM19 9H1C.59 9 .25 8.546.25 8c0-.547.34-1 .75-1h18c.41 0 .75.453.75 1 0 .546-.34 1-.75 1zM1 15.666h18c.41 0 .75-.453.75-1 0-.546-.34-1-.75-1H1c-.41 0-.75.454-.75 1 0 .547.34 1 .75 1z"
      fill="#fff"
    />
  </svg>
);

export default LogoutIcon;
