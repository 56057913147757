import { SVGAttributes } from 'react';

const MinusSquareOutlinedIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 15.165h4c3.62 0 5.166-1.546 5.166-5.166v-4c0-3.62-1.546-5.167-5.166-5.167H6C2.38.832.833 2.379.833 5.999v4c0 3.62 1.547 5.166 5.167 5.166zM1.833 6c0-3.074 1.093-4.167 4.167-4.167h4c3.073 0 4.166 1.093 4.166 4.167v4c0 3.073-1.093 4.166-4.166 4.166H6c-3.074 0-4.167-1.093-4.167-4.166v-4zm3.5 2.5h5.333c.274 0 .5-.227.5-.5 0-.274-.226-.5-.5-.5H5.333c-.273 0-.5.226-.5.5 0 .273.227.5.5.5z"
      fill="#333"
    />
  </svg>
);

export default MinusSquareOutlinedIcon;
