export const PermissionAction = {
  // Common
  create: 'create',
  view: 'view',
  update: 'update',
  delete: 'delete',
  // Session
  supervisor: 'supervisor',
  resolver: 'resolver',
  manager: 'manager',
  viewer: 'viewer',
  view_as_supervisor: 'view_as_supervisor',
  view_as_resolver: 'view_as_resolver',
};

export const Modules = {
  tasks: 'tasks',
  templates: 'templates',
  task_groups: 'task_groups',
  group_permissions: 'group_permissions',
  sessions: 'sessions',
};

export const AllPermission = {
  Tasks__Create: 'tasks__create',
  Tasks__View: 'tasks__view',
  Tasks__Update: 'tasks__update',
  Tasks__Delete: 'tasks__delete',

  Templates__Create: 'templates__create',
  Templates__View: 'templates__view',
  Templates__Update: 'templates__update',
  Templates__Delete: 'templates__delete',

  Task_groups__Create: 'task_groups__create',
  Task_groups__View: 'task_groups__view',
  Task_groups__Update: 'task_groups__update',
  Task_groups__Delete: 'task_groups__delete',

  Group_permissions__Create: 'group_permissions__create',
  Group_permissions__View: 'group_permissions__view',
  Group_permissions__Update: 'group_permissions__update',
  Group_permissions__Delete: 'group_permissions__delete',

  Sessions__Supervisor: 'sessions__supervisor',
  Sessions__Resolver: 'sessions__resolver',
  Sessions__Manager: 'sessions__manager',
  Sessions__Viewer: 'sessions__viewer',
  Sessions__View_as_supervisor: 'sessions__view_as_supervisor',
  Sessions__View_as_resolver: 'sessions__view_as_resolver',

  All__Allowed: '*',
};
