import {Tooltip} from 'antd';
import {FeedbackIcons} from 'antd/es/form/FormItem';
import {CheckCircleOutlined, InfoCircleOutlined} from '@ant-design/icons';

import {Permission} from '~models';

export interface PermissionForm {
  name: string;
  permissions: Permission[];
}

export const initialPermissionValues: PermissionForm = {
  name: '',
  permissions: [],
};

export const feedbackIcons: FeedbackIcons = ({errors}) => ({
  error: (
    <Tooltip title={errors}>
      <InfoCircleOutlined style={{color: 'red'}} />
    </Tooltip>
  ),
  success: <CheckCircleOutlined style={{color: '#2560e5'}} />,
});
