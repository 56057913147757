import queryString from 'query-string';
import axios, {AxiosError, AxiosResponse, HttpStatusCode, InternalAxiosRequestConfig} from 'axios';

import {API_URL, PORTAL_API, SHARE_COOKIE_DOMAIN} from '~configs';
import {Cookies, refreshAccessToken} from '~utils';
import {store} from '~store';
import {setAuthTokenAction} from '~reducers';

let refreshTokenRequest: any = null;

export const axiosClient = axios.create({
  baseURL: API_URL,
  paramsSerializer: params =>
    queryString.stringify(params, {skipEmptyString: true, skipNull: true}),
});

const requestHandler = (config: InternalAxiosRequestConfig) => {
  const accessToken = store.getState().authReducer.token;

  config.headers.set('Authorization', `Bearer ${accessToken}`);
  config.params = {
    ...config.params,
    version: Date.now(),
  };

  return config;
};

const responseErrorHandler = async (err: AxiosError) => {
  const originalRequest: any = err.config;

  if (err.response?.status === HttpStatusCode.Unauthorized) {
    try {
      const refreshToken = store.getState().authReducer.refreshToken;
      refreshTokenRequest = refreshTokenRequest
        ? refreshTokenRequest
        : axios({
            method: 'POST',
            baseURL: PORTAL_API,
            url: '/token/refresh',
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          });
      const response: AxiosResponse<any> = await refreshTokenRequest;
      const accessToken = response.data.data.access_token;
      if (accessToken) {
        store.dispatch(
          setAuthTokenAction({
            token: accessToken,
          }),
        );
        Cookies.set('token', accessToken, {
          domain: SHARE_COOKIE_DOMAIN,
          secure: true,
        });
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return axiosClient(originalRequest);
      }
    } catch (e: any) {
      refreshAccessToken();
      return Promise.reject(e.response?.data ? e.response?.data : e);
    } finally {
      refreshTokenRequest = null;
    }
  } else if (err.response?.status === HttpStatusCode.Forbidden) {
    return Promise.reject({
      response: {
        data: {
          message: 'Bạn không có quyền trên tính năng này',
        },
      },
    });
  }

  return Promise.reject(err);
};

axiosClient.interceptors.request.use(requestHandler, err => Promise.reject(err));
axiosClient.interceptors.response.use((response: AxiosResponse) => response, responseErrorHandler);
